section.page-error {

    .title {
        @include mobile-large {
            font-size: rem(45) !important;
        }
    }

    .error {
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: rem(96) 0;

        @include mobile-large {
            margin: rem(64) 0;
        }

        >p {
            @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
            font-weight: $FRegular;
            color: $black2;
            line-height: rem(20);
            margin-bottom: rem(80);
            text-align: center;

            @include mobile-large {
                margin-bottom: rem(48);

            }
        }

    }
}