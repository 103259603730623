@use "sass:math";

@function strip-unit($number) {
    @if type-of($number)=="number" and not unitless($number) {
        @return math.div($number, $number * 0 + 1);
    }

    @return $number;
}

@function vw($window_width, $size) {
    @return math.div($size, $window_width) * 100vw;
}

// remへの計算式（16pxを基準としている。10pxを基準とする場合は16を10に変更する）
@function orem($pixels) {
    @return math.div($pixels, 16) * 1rem;
}

/* リキッドレイアウト対応 */
// コンテンツのインナー幅（最も共通の幅が多い部分）
$inner: 1100px;

html {

    //~375px
    @media (max-width: 375px) {
        font-size: vw(375, 16);
    }

    //375px~640px
    font-size: 14px;

    @media (max-width: $inner) and (min-width: 767px) {
        font-size: vw(strip-unit($inner), 16);
    }

    //inner~max-screen
    @media (min-width: $inner) {
        font-size: 16px;
    }
}

//レスポンシブ
$pc: 1080px; // PC
$tab: 1079px; // タブレット
$sp: 767px; // スマホ

//タブレット以下
@mixin tab {
    @media (max-width: ($tab)) {
        @content;
    }
}

//スマホ以下
@mixin sp {
    @media (max-width: ($sp)) {
        @content;
    }
}

.top {
    position: relative;

    .sp {
        display: none;

        @include sp {
            display: block;
        }
    }

    .pc {
        @include sp {
            display: none;
        }
    }

    .pc-only {
        @include tab {
            display: none;
        }

        @include sp {
            display: none;
        }
    }


    .tab {
        display: none;

        @include tab {
            display: block;
        }

        @include sp {
            display: none;
        }
    }

    //リセット
    button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
    }

    .button {
        width: orem(280);
        height: orem(72);
        font-weight: $FBold;

        @include sp {
            width: orem(228);
            height: orem(64);
        }
    }

    .lwrap {
        margin: 0 auto;
        // width: min(1280px, 90%);
        max-width: calc(1280px + 40px);
        padding: 0 20px;

        @include tab {
            max-width: orem(800);
        }
    }

    .rwrap {
        max-width: 1180px;
        width: 100%;
    }

    .swrap {
        max-width: 1080px;
        width: 100%;

        @media (max-width: 1200px) {
            max-width: 1040px;
        }
    }

    h2 {
        font-family: $Oswald;
        font-size: orem(175);
        font-weight: $FSemibold;
        color: $white;

        @media (max-width: 1441px) {
            font-size: orem(160);
        }

        @media (max-width: 1200px) {
            font-size: orem(120);
        }

        @include tab {
            font-size: orem(100);
        }

        // @media (max-width: 1000px) {
        //     font-size: orem(100);
        // }

        @include sp {
            font-size: orem(80);
        }
    }

    //svg
    .about {
        .button {
            margin-top: orem(48);
            position: relative;
            z-index: 2;

            @include tab {
                transform: translateY(orem(30));
            }

            @include sp {
                margin-top: orem(40);
                transform: initial;
            }
        }

        .lwrap {
            position: relative;
        }

        .swrapTab {
            position: absolute;
            max-width: orem(500);
            height: orem(134);
            bottom: orem(0);
            left: 50%;
            transform: translateX(-50%);

            .scrollsvgTab {
                position: absolute;
                width: orem(500);
                left: 50%;
                transform: translateX(-50%);
                bottom: orem(-160);

                svg {
                    width: orem(500);
                    height: orem(134);
                }
            }
        }

        .svgwrapSP {
            width: orem(133);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            bottom: orem(-394);

            .scrollsvgSP {
                svg {
                    width: orem(133);
                    height: orem(443);
                }
            }
        }

        .swrap {
            position: absolute;
            height: orem(220);
            bottom: orem(-220);
            left: 50%;
            transform: translateX(-50%);
        }

        .scrollsvg {
            position: absolute;
            width: 100%;
            max-width: 1080px;
            height: 220px;
            right: orem(0);
            bottom: 0;

            svg {
                width: 100%;
                max-width: 1080px;
                height: 220px;
            }
        }
    }

    .mv {
        height: 100vh;
        width: 100%;
        background: grey;
        position: relative;

        .mv__video {
            overflow: hidden;
            width: 100%;
            height: 100vh;
            position: absolute;
            top: 0;
            left: 0;
            content: "";

            video {
                object-fit: cover;
                width: 100vw;
                height: 100vh;
            }
        }

        .mv__internship {
            position: absolute;
            content: "";
            right: rem(40);
            bottom: rem(40);
            cursor: pointer;
            transition: ease 0.3s;

            @include sp {
                right: rem(16);
                bottom: rem(40);

                .banner {
                    width: orem(240);
                    height: auto;
                }
            }

            .close {
                position: absolute;
                content: "";
                right: orem(-5);
                top: orem(-5);
            }

            &:hover {
                opacity: 0.8;
            }
        }

        .mv__text {
            position: absolute;
            content: "";
            bottom: orem(80);
            left: orem(80);
            width: orem(640);
            height: orem(135);

            @include sp {
                width: orem(320);
                left: orem(16);
                bottom: orem(110);
            }

            h1 {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                font-size: orem(100);
                color: $white;
                width: inherit;
                font-weight: $FBold;
                letter-spacing: 0.09em;

                @include tab {
                    font-size: orem(80);
                }

                @include sp {
                    font-size: orem(90);
                    top: 0;
                    bottom: initial;
                    width: fit-content;
                }

                span {
                    position: absolute;
                    right: 0;
                    top: orem(-40);
                    font-size: orem(140);

                    @include tab {
                        font-size: orem(120);
                        left: orem(270);
                    }

                    @include sp {
                        font-size: orem(110);
                        top: orem(100);
                        right: initial;
                        left: orem(0);
                    }
                }

                small {
                    position: absolute;
                    right: orem(-80);
                    top: orem(0);
                    font-size: orem(100);

                    @include tab {
                        font-size: orem(80);
                        right: orem(40);
                    }

                    @include sp {
                        right: orem(-20);
                        top: orem(120);
                        font-size: orem(90);
                        width: fit-content;
                    }
                }
            }

            strong {
                position: absolute;
                top: 0;
                left: orem(0);
                font-size: rem(34);
                font-weight: $FBold;
                color: $white;
                font-family: $Oswald;

                @include tab {
                    font-size: rem(28);
                }

                @include sp {
                    font-size: rem(30);
                    top: orem(-30);
                }
            }
        }
    }

    .about {
        padding-top: orem(80);
        padding-bottom: orem(200);

        @include tab {
            padding-bottom: orem(180);
        }

        @include sp {
            padding-bottom: orem(350);
        }

        .lwrap {
            .about__flex {
                display: flex;
                justify-content: space-between;
                height: orem(460);

                @include tab {
                    height: auto;
                }

                @include sp {
                    height: initial;
                    flex-direction: column;
                }
            }

            .about__l {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include sp {
                    left: calc(50% - orem(164));
                    position: relative;
                    width: fit-content;
                }
            }

            .about__r {
                position: relative;
                right: orem(110);
                padding-top: orem(32);

                @include sp {
                    right: inherit;
                    padding-top: 0;
                    z-index: 3;
                }

                .about__r-wrap {
                    margin: 0 auto;
                    width: orem(315);
                    height: orem(315);
                    position: relative;
                    perspective: 1440px;

                    @include tab {
                        width: orem(240);
                        height: orem(240);
                    }

                    @include sp {
                        width: orem(180);
                        height: orem(180);
                        perspective: 768px;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        margin-top: orem(64);
                    }

                    .carousel {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        transform-style: preserve-3d;
                        transition: transform 1s;

                        @include sp {
                            z-index: 3;
                        }
                    }

                    .item {
                        display: block;
                        position: absolute;
                        background: $white;
                        width: orem(330);
                        height: orem(330);
                        text-align: center;
                        border: 10px solid $green-theme;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @include tab {
                            width: orem(240);
                            height: orem(240);
                        }

                        @include sp {
                            width: orem(180);
                            height: orem(180);
                            left: 0;
                        }

                        figure {
                            display: flex;
                            align-items: center;
                            flex-direction: column;

                            figcaption {
                                font-size: orem(24);
                                color: $green-theme;
                                font-weight: $FBold;
                                margin-bottom: orem(20);

                                @include sp {
                                    font-size: orem(13);
                                }
                            }

                            .item-img {
                                width: orem(136);
                                height: auto;

                                @include tab {
                                    width: orem(90);
                                }

                                @include sp {
                                    width: orem(80);
                                }
                            }

                            .item-menwomen {
                                margin-top: orem(16);

                                @include sp {
                                    margin-top: orem(8);
                                    width: orem(80);
                                    height: auto;
                                }
                            }

                            p {
                                font-size: orem(64);
                                color: $green-theme;
                                font-weight: $FSemibold;
                                font-family: $Oswald;

                                @include sp {
                                    font-size: orem(36);
                                }

                                span {
                                    font-family: $Oswald;
                                    font-size: orem(25);
                                    color: $black-theme;

                                    @include sp {
                                        font-size: orem(13);
                                    }
                                }

                                small {
                                    font-size: rem(16);
                                    margin-top: rem(8);
                                    font-weight: $FBold;
                                    display: block;
                                    color: $black-theme;

                                    @include tab {
                                        font-size: rem(10);
                                        margin-top: rem(4);
                                    }

                                    @include sp {
                                        font-size: rem(10);
                                        line-height: 1.2;
                                    }
                                }
                            }
                        }
                    }

                    .a {
                        transform: rotateY(0deg) translateZ(orem(240));

                        @include tab {
                            transform: rotateY(0deg) translateZ(orem(180));
                        }

                        @include sp {
                            transform: rotateY(0deg) translateZ(orem(160));

                            .item-img {
                                @include sp {
                                    width: rem(50) !important;
                                    height: auto;
                                }
                            }
                        }
                    }

                    .b {
                        transform: rotateY(72deg) translateZ(orem(240));

                        @include tab {
                            transform: rotateY(72deg) translateZ(orem(180));
                        }

                        @include sp {
                            transform: rotateY(72deg) translateZ(orem(160));
                        }
                    }

                    .c {
                        transform: rotateY(144deg) translateZ(orem(240));

                        .item-img {
                            width: orem(250) !important;
                            height: auto;

                            @include tab {
                                width: orem(180) !important;
                                height: auto;
                            }

                            @include sp {
                                width: orem(140) !important;
                                height: auto;
                            }
                        }

                        @include tab {
                            transform: rotateY(144deg) translateZ(orem(180));
                        }

                        @include sp {
                            transform: rotateY(144deg) translateZ(orem(160));
                        }
                    }

                    .d {
                        transform: rotateY(216deg) translateZ(orem(240));

                        @include tab {
                            transform: rotateY(216deg) translateZ(orem(180));
                        }

                        @include sp {
                            transform: rotateY(216deg) translateZ(orem(160));
                        }
                    }

                    .e {
                        transform: rotateY(288deg) translateZ(orem(240));

                        @include tab {
                            transform: rotateY(288deg) translateZ(orem(180));
                        }

                        @include sp {
                            transform: rotateY(288deg) translateZ(orem(160));
                        }
                    }
                }

                .about__r-buttons {
                    display: flex;
                    justify-content: center;
                    position: absolute;
                    bottom: orem(0);
                    left: 50%;
                    transform: translateX(-50%);

                    @include tab {
                        bottom: orem(-30);
                    }

                    @include sp {
                        top: orem(280);
                        position: relative;
                        z-index: 2;
                    }
                }

                .next,
                .prev {
                    width: orem(200);
                    height: orem(48);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include fluid-type(orem(620), orem(1400), orem(16), orem(16));
                    font-weight: $FBold;
                    color: $white;

                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    @include tab {
                        width: orem(140);
                    }


                    @include sp {
                        width: orem(120);
                    }

                    &:hover {
                        color: $black-theme;

                        &::after {
                            background-color: #fff;
                        }

                        &::before {
                            background-color: $black-theme;
                        }

                        .btn-text {
                            &:first-child {
                                transform: translateY(0);
                            }

                            &:last-child {
                                transform: translateY(orem(-24));
                            }
                        }

                        .circle {
                            background-color: $black-theme;
                            transform: scale(1.5);
                            transition: all 0.5s ease;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 50% 50% 0 0;
                        transform: translateY(100%) scaleY(.5);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: $green-theme;
                        border-radius: 0;
                        transform: translateY(0) scaleY(1);
                    }

                    >div {
                        position: relative;
                        width: 100%;
                        height: orem(16);
                        text-transform: uppercase;
                        overflow: hidden;
                    }

                    .btn-text {
                        position: absolute;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        transition: transform .3s ease;

                        &:first-child {
                            color: $black-theme;
                            transform: translateY(orem(24));
                        }

                        &:last-child {
                            color: #fff;
                            transform: translateY(0);
                        }

                    }
                }

                .next {
                    border-bottom-right-radius: orem(20);
                    border-left: #FFF 1px solid;

                    .circle {
                        background: #fff;
                        width: orem(8);
                        height: orem(8);
                        margin: auto;
                        border-radius: 100%;
                        z-index: 1;
                        position: absolute;
                        right: 11%;
                        transition: all 0.5s ease;
                    }
                }

                .prev {
                    border-bottom-left-radius: orem(20);
                    border-right: #FFF 1px solid;

                    .circle {
                        background: #fff;
                        width: orem(8);
                        height: orem(8);
                        margin: auto;
                        border-radius: 100%;
                        z-index: 1;
                        position: absolute;
                        left: 11%;
                        transition: all 0.5s ease;
                    }
                }
            }
        }
    }


    .special {
        background: url(../images/top/top-bk.webp) no-repeat center center /cover;
        width: 100%;
        position: relative;

        @include sp {
            padding-top: orem(60);
            padding-bottom: orem(40);
        }

        .svgwrapSP {
            position: absolute;
            left: calc(50% + orem(48));
            transform: translateX(-50%);
            content: "";
            top: orem(160);
            width: orem(113);
            z-index: 1;

            @include sp {
                top: orem(145);
            }

            .scrollsvgSP {
                width: orem(133);
                height: orem(665);

                svg {
                    width: orem(133);
                    height: orem(665);
                }
            }
        }

        .slider {
            .slick-list {
                padding: initial !important;
            }

            width: 100%;
        }

        .special__videosSP {
            margin: orem(120) auto 0 auto;

            @include sp {
                margin: orem(-20) auto 0 auto;
                width: 100%;
            }

            .special__videoSP {
                margin: 0 16px;
                position: relative;
                width: orem(220);
                height: orem(370);

                &:before {
                    position: absolute;
                    background: url(../images/top/sumaho.webp) no-repeat center center /contain;
                    content: "";
                    left: 0;
                    top: 0;
                    width: inherit;
                    height: 100%;
                }

                &:after {
                    position: absolute;
                    background: url(../images/top/sumaho-top.webp) no-repeat center center /contain;
                    content: "";
                    left: 50%;
                    top: orem(5);
                    transform: translateX(-50%);
                    width: orem(82);
                    height: orem(13);
                    z-index: 3;
                }
            }

            .special__videoSP video {
                z-index: 2;
                width: orem(200);
                height: orem(350);
                object-fit: cover;
                position: absolute;
                cursor: pointer;
                scale: var(--video-scale);
                transition: scale .8s ease-out;
                border-radius: orem(24);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .specialbefore {
            padding-top: orem(73);

            .svgTabWrap {
                position: absolute;
                width: orem(500);
                left: 50%;
                top: 0;
                transform: translateX(-50%);
                content: "";

                .scrollsvgTab {
                    position: absolute;
                    right: orem(6);
                    top: orem(-94);
                    content: "";
                    width: orem(18);
                    height: orem(550);

                    svg {
                        width: orem(18);
                        height: orem(550);
                    }
                }
            }
        }

        .specailafter {
            padding-bottom: orem(24);

            @include sp {
                padding-bottom: orem(0) !important;
            }

            .svgTabWrap {
                position: absolute;
                width: orem(500);
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                content: "";
                z-index: 1;

                .scrollsvgTab {
                    position: absolute;
                    left: orem(10);
                    bottom: orem(-180);
                    content: "";
                    width: orem(245);
                    height: orem(131);

                    svg {
                        width: orem(245);
                        height: orem(131);
                    }
                }
            }

            @include sp {
                padding-bottom: orem(400);
                position: relative;
                z-index: 3;
            }
        }

        .swrap {
            margin: 0 auto;
            position: relative;

            .button {
                position: absolute;
                content: "";
                bottom: orem(-140);
                left: orem(260);
                z-index: 4;

                @media (max-width: 1440px) {
                    bottom: orem(-120);
                }

                @media (max-width: 1200px) {
                    bottom: orem(-100);
                }

                @include tab {
                    bottom: orem(-100);
                    left: orem(0);
                }

                @include sp {
                    position: relative;
                    left: calc(50% + orem(60));
                    transform: translate(-50%, orem(-40));
                }
            }

            .curve {
                position: absolute;
                content: "";
                left: orem(148);
                bottom: orem(-181);
                width: orem(435);
                height: orem(90);
                z-index: 3;

                @media (max-width: 1440px) {
                    bottom: orem(-164);
                    left: orem(148);
                }

                @media (max-width: 1200px) {
                    bottom: orem(-140);
                    left: orem(133);
                }

                svg {
                    width: orem(435);
                    height: orem(90);

                    @include tab {
                        width: orem(400);
                        height: orem(50);
                    }
                }
            }

            .line {
                position: absolute;
                right: orem(33);
                top: orem(-73);
                content: "";
                width: orem(25);
                height: orem(500);

                @media (max-width: 1200px) {
                    right: orem(30);
                }

                @include tab {
                    width: orem(25);
                    height: orem(600);
                    top: orem(-80);
                    right: orem(2);
                }

                svg {
                    width: orem(25);
                    height: orem(500);

                    @include tab {
                        width: orem(25);
                        height: orem(600);
                    }
                }
            }
        }

        h2 {
            text-align: right;
            color: $black-theme;
            margin-top: orem(70);
            position: relative;
            z-index: 1;

            @include sp {
                left: calc(50% + orem(55));
                transform: translate(-50%, orem(-210));
                width: fit-content;
            }
        }

        .special__videos {
            display: flex;
            gap: orem(60);
            width: orem(843);

            @include tab {
                width: orem(700);
                gap: orem(40);
            }

            .special__video {
                background: url(../images/top/sumaho.webp) no-repeat center center /contain;
                width: calc(33% - orem(60) * 2/3);
                height: orem(394);
                position: relative;
                z-index: 3;
                overflow: hidden;

                &:after {
                    position: absolute;
                    background: url(../images/top/sumaho-top.webp) no-repeat center center /contain;
                    content: "";
                    left: 50%;
                    top: orem(5);
                    transform: translateX(-50%);
                    width: orem(82);
                    height: orem(13);
                    z-index: 3;

                    @include tab {
                        top: orem(24);
                    }
                }

                @include tab {
                    width: calc(33% - orem(40) * 2/3);
                }

                video {
                    z-index: 2;
                    width: 90%;
                    height: 96%;
                    object-fit: cover;
                    position: absolute;
                    cursor: pointer;
                    border-radius: orem(30);
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: transform 0.3s ease-out;


                    @include tab {
                        border-radius: orem(20);
                        width: 92%;
                        height: 84%;
                    }
                }
            }
        }

        .special-line {
            figure {
                position: absolute;
                content: "";
                right: orem(42);
                top: orem(300);
                width: orem(25);
                height: orem(500);
                top: orem(-180);

                img {
                    width: orem(25);
                    height: orem(500);
                }

                .special-line-color {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                    content: "";
                    object-fit: cover;
                    height: 100px;
                    background: url("../images/top/special-line-color.webp") no-repeat top center /cover;
                    width: inherit;
                    height: 0%;
                    transition: height 0s linear;
                }
            }
        }
    }

    .work {
        background: $black-theme;
        position: relative;

        .svgTabWrap {
            position: absolute;
            width: orem(500);
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            content: "";
            z-index: 1;

            .scrollsvgTab {
                position: absolute;
                left: orem(10);
                bottom: orem(-178);
                content: "";
                width: orem(430);
                height: orem(660);

                svg {
                    width: orem(430);
                    height: orem(660);
                }
            }
        }


        .svgwrapSP {
            position: absolute;
            width: orem(113);
            height: auto;
            content: "";
            left: calc(50% + orem(20));
            transform: translateX(-50%);
            top: orem(240);
            z-index: 2;

            .scrollsvgSP {
                width: orem(113);
                height: orem(625);

                svg {
                    width: orem(113);
                    height: orem(625);
                }
            }
        }

        .lwrap {
            position: relative;
            padding-top: orem(80);
            padding-bottom: orem(180);

            @media (max-width: 1200px) {
                padding-bottom: orem(250);
            }

            @include tab {
                padding-top: orem(40);
                padding-bottom: orem(80);
            }

            @include sp {
                padding-top: orem(55);
                padding-bottom: orem(72);
            }

            h2 {
                background: $black-theme;
                position: relative;
                z-index: 4;
                padding: orem(24) 0 orem(24) 0;

                @include sp {
                    text-align: center;
                    padding-top: orem(16);
                }
            }
        }

        .swrap {
            margin: 0 auto;
        }

        .svgwrap {
            position: absolute;
            content: "";
            top: orem(-455);
            left: 50%;
            transform: translateX(-50%);
            width: orem(1080);
            height: orem(1000);
            z-index: 2;

            @include tab {
                width: orem(420);
                height: orem(920);
            }

            .scrollsvg {
                position: absolute;
                right: orem(34);
                bottom: orem(-316);
                width: orem(890);

                @media (max-width: 1200px) {
                    right: orem(9);
                    bottom: orem(-330);
                }

                @include tab {
                    right: inherit;
                    left: 50%;
                    transform: translateX(-50%);
                    width: orem(420);
                    height: orem(920)
                }

                svg {
                    width: orem(890);

                    @include tab {
                        width: orem(460);
                        height: orem(920)
                    }
                }
            }
        }

        .work__main {
            display: flex;
            gap: orem(120);
            margin-top: orem(120);
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            @include sp {
                flex-direction: column;
                gap: 0;
                margin: 0;
            }

            .work__main_l {
                display: flex;
                flex-direction: column;
                gap: orem(20);

                @include tab {
                    position: relative;
                    z-index: 4;
                }

                @include sp {
                    order: 2;
                    gap: orem(16);
                    margin-top: orem(250);
                }

                .button {
                    margin-top: 0;
                    position: relative;
                    z-index: 4;
                }
            }

            .work__main_r {
                width: orem(604);
                position: relative;

                @include sp {
                    width: 100%;
                    order: 1;
                    transform: translateY(orem(20));
                    z-index: 3;
                }


                .word__main-r-wrap {
                    position: absolute;
                    content: "";
                    left: 0;
                    top: orem(-220);
                    width: 100%;

                    @include sp {
                        position: initial;
                    }
                }

                figure {
                    width: 100%;
                    height: auto;
                    transition: opacity 0.5s ease;
                    position: absolute;
                    top: 0;
                    content: "";
                    left: 0;

                    @include sp {
                        top: orem(0);
                        text-align: center;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        aspect-ratio: 604/349;
                        object-fit: cover;
                        max-width: orem(604);

                        @include sp {
                            max-width: orem(345);
                            aspect-ratio: 354/200;
                        }
                    }

                    &.is-active {
                        opacity: 1;
                    }
                }
            }

            .work__main_r figure:not(.is-active) {
                opacity: 0;
            }
        }
    }

    .benefit {
        background: url(../images/top/benefit-bk.webp) no-repeat center center /cover;
        width: 100%;
        padding: orem(80) 0 orem(80) 0;
        position: relative;

        @include tab {
            padding: orem(80) 0 orem(120) 0;
        }

        @include sp {
            padding: orem(100) 0 orem(96) 0;
        }

        .svgTabWrap {
            position: absolute;
            width: orem(500);
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            content: "";
            z-index: 1;

            &.before {
                .scrollsvgTab {
                    position: absolute;
                    left: calc(50% + orem(180));
                    transform: translateX(-50%);
                    bottom: orem(355);
                    content: "";
                    width: orem(18);
                    height: orem(180);
                    z-index: 2;

                    svg {
                        width: orem(18);
                        height: orem(180);
                    }
                }
            }

            &.after {
                .scrollsvgTab {
                    position: absolute;
                    left: calc(50% + orem(87));
                    transform: translateX(-50%);
                    bottom: orem(-251);
                    content: "";
                    width: orem(206);
                    height: orem(460);

                    svg {
                        width: orem(206);
                        height: orem(460);
                    }
                }
            }
        }

        .svgwrapSP {
            &.before {
                position: absolute;
                top: 0;
                left: calc(50% + orem(57));
                transform: translate(-50%);
                width: orem(16);
                height: orem(100);
                content: "";
                z-index: 2;

                .scrollsvgSP {
                    width: orem(16);
                    height: orem(100);

                    svg {
                        width: orem(16);
                        height: orem(100);
                    }
                }
            }

            &.after {
                position: absolute;
                width: orem(113);
                height: auto;
                content: "";
                left: calc(50% + orem(18));
                transform: translateX(-50%);
                top: orem(210);
                z-index: 1;

                .scrollsvgSP {
                    width: orem(113);
                    height: orem(655);

                    svg {
                        width: orem(113);
                        height: orem(655);
                    }
                }
            }
        }

        .lwrap {
            position: relative;

            @include sp {
                display: flex;
                flex-direction: column;
                gap: orem(40);
            }

            .benefit__right {
                position: absolute;
                top: orem(100);
                right: 0;
                width: orem(560);

                @include sp {
                    position: static;
                    width: 100%;
                    order: 1;
                }

                .green-button {
                    position: relative;
                    z-index: 2;
                    transform: translate(orem(240), orem(80));

                    @media (max-width: 1200px) {
                        transform: translate(orem(240), orem(300));
                    }

                    @include sp {
                        margin-top: orem(40);
                        transform: initial;
                        left: calc(50% + orem(55));
                        transform: translateX(-50%);
                    }

                    &::after {
                        background: $black-theme;
                    }

                    &:hover {
                        color: $black-theme;

                        &::after {
                            background-color: #fff;
                        }
                    }
                }

                h2 {
                    color: $black-theme;

                    @media (max-width: 1200px) {
                        position: absolute;
                        right: 0;
                        top: orem(30);
                        content: "";
                        width: fit-content;
                    }

                    @include tab {
                        right: 0;
                        top: orem(15);
                        width: fit-content;
                    }

                    @include sp {
                        position: relative;
                        left: calc(50% + orem(50));
                        transform: translate(-50%, orem(0));
                        width: fit-content;
                    }
                }
            }
        }

        .swrap {
            margin: 0 auto;
            position: relative;

            @include sp {
                order: 2;
            }
        }

        .svgwrap {
            &.before {
                .scrollsvg {
                    right: orem(45);
                    bottom: orem(418);
                    width: orem(25) !important;
                    height: orem(160);
                    z-index: 2;

                    @media (max-width: 1440px) {
                        right: orem(46.5);
                        bottom: orem(418);
                    }

                    @media (max-width: 1200px) {
                        right: orem(21);
                        bottom: orem(420);
                    }

                    svg {
                        width: orem(25);
                    }
                }
            }

            &.after {
                position: absolute;
                content: "";
                top: orem(-440);
                left: 50%;
                transform: translateX(-50%);
                width: orem(1080);
                height: orem(455);
                z-index: 2;

                .scrollsvg {
                    right: orem(25);
                    bottom: orem(-730);
                    width: orem(535);

                    svg {
                        width: orem(535);
                    }
                }
            }

            .scrollsvg {
                position: absolute;

                @media (max-width: 1200px) {
                    bottom: orem(-780);
                }
            }
        }

        .benefit__main {
            .benefit__main_r {
                width: orem(500);
                display: flex;
                flex-wrap: wrap;
                gap: orem(32);

                @include tab {
                    width: orem(450);
                    gap: orem(8);
                }

                @include sp {
                    width: 100%;
                    gap: orem(8);
                    position: relative;
                    z-index: 2;
                }

                li {
                    width: calc(33% - orem(32) * 2/3);
                    height: orem(145);
                    background: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include tab {
                        width: calc(33% - orem(8) * 2/3);
                    }

                    @include sp {
                        width: calc(33% - orem(8) * 2/3);
                        height: orem(109);
                    }

                    figure {
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        align-items: center;
                        justify-content: center;

                        figcaption {
                            font-size: orem(16);
                            font-weight: $FBold;

                            @include sp {
                                font-size: orem(13);
                            }
                        }
                    }
                }
            }
        }
    }

    .applications {
        background: url(../images/top/applications-bk.webp) no-repeat center center /cover;
        width: 100%;
        height: orem(640);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        @include sp {
            height: orem(395);
        }

        h2 {
            @include sp {
                font-size: orem(58);
            }
        }

        .applications__line {
            position: absolute;
            left: calc(50% - 11px);
            transform: translate(-50%, orem(250));

            &.before {
                z-index: 3;

                .scrollsvg {
                    top: orem(-570);
                    height: orem(200);
                }
            }

            &.after {
                .scrollsvg {
                    top: orem(-160);
                    height: orem(300);
                }
            }


            .scrollsvg {
                position: absolute;
                width: orem(20);
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .scrollsvg {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            bottom: orem(-240);

            svg {
                path {
                    width: orem(25);
                    height: orem(460);
                }
            }
        }

        .svgTabWrap {
            &.before {
                position: absolute;
                content: "";
                left: calc(50% - orem(8));
                transform: translateX(-50%);
                width: orem(18);
                height: orem(220);
                top: orem(-4);
                z-index: 2;
            }

            &.after {
                position: absolute;
                width: orem(500);
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                content: "";
                z-index: 1;

                .scrollsvgTab {
                    position: absolute;
                    left: calc(50% - orem(10));
                    transform: translateX(-50%);
                    bottom: orem(-80);
                    content: "";
                    width: orem(18);
                    height: orem(360);

                    svg {
                        width: orem(18);
                        height: orem(360);
                    }
                }
            }
        }

        .svgwrapSP {
            &.before {
                position: absolute;
                left: calc(50% - orem(4));
                transform: translateX(-50%);
                content: "";
                width: orem(18);
                height: orem(100);
                top: orem(-2);
                z-index: 2;
            }

            &.after {
                position: absolute;
                left: calc(50% - orem(3));
                transform: translateX(-50%);
                content: "";
                width: orem(18);
                height: orem(285);
                top: orem(190);
            }
        }

        .button {
            z-index: 2;
            transform: translateY(orem(60));

            @include sp {
                transform: initial;
                margin-top: orem(40);
            }
        }

        .lwrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .scrollsvg {
            position: absolute;
            content: "";
            left: 50%;
            transform: translateX(-50%);
            top: orem(360);
        }
    }

    .faq {
        padding: orem(90) 0;

        @include sp {
            padding: orem(90) 0;
        }

        .lwrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .button {
            position: relative;
            z-index: 2;
            transform: translateY(orem(100));

            @include sp {
                margin-top: orem(40);
                transform: initial;
            }
        }
    }

    .entry {
        padding-top: orem(760);
        position: relative;
        margin-top: 200px;
        transform: translateY(-200px);
        z-index: 1;

        @include tab {
            padding-top: orem(500);
        }

        @include sp {
            padding-top: orem(300);
        }

        .lwrap {
            .entry__main {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: absolute;
                top: orem(300);
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
            }
        }

        .entry__textImg {
            position: absolute;
            content: "";
            left: 50%;
            transform: translateX(-50%);
            width: orem(485);
            height: orem(620);
            top: orem(-430);

            @include tab {
                width: orem(270);
                height: orem(480);
            }

            @include sp {
                width: orem(195);
                height: orem(436);
                top: orem(-480);
            }

            .white-text {
                position: absolute;
                left: 0;
                top: 0;
                width: orem(485);
                height: orem(620);

                @include tab {
                    width: orem(270);
                    height: orem(480);
                }

                @include sp {
                    width: orem(195);
                    height: orem(436);
                }
            }

            .test {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 3;
                content: "";
                background: url("../images/top/entry-line-blue.webp") no-repeat top center /cover;
                width: inherit;
                height: 0%;
                transition: height 0s linear;

                @include tab {
                    background: url("../images/top/entry-line-blue-bk-tab.webp") no-repeat top center /cover;
                }

                @include sp {

                    background: url("../images/top/entry-line-blue-bk-sp.png") no-repeat top center /cover;
                }
            }
        }

        .button {
            position: absolute;
            top: orem(300);
            left: 50%;
            transform: translateX(-50%);

            @include tab {
                top: orem(100);
            }

            @include sp {
                top: orem(20);
            }
        }
    }

    .bk {
        position: absolute;
        bottom: orem(-100);
        left: 0;
        width: 100%;
        height: orem(960);
        background: url(../images/top/entry-bk.webp) no-repeat center center /cover;

        @include sp {
            height: orem(500);
            bottom: orem(-200);
        }
    }
}

body {
    position: relative;
}

footer {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    // height: orem(70);
    // z-index: 2;

    @include sp {}
}

header {
    .container {
        @include sp {
            transition: ease-in 0.5s;
            top: orem(-70) !important;

            &.is-show {
                top: 0 !important;
            }
        }
    }
}