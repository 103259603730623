footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 9;

    .container {
        display: flex;
        flex-direction: column;
        gap: rem(17);
        margin: rem(16) 0;

        @include mobile-large {
            margin: rem(24) 0;
        }

        .text {
            display: flex;
            justify-content: space-between;

            @include mobile-large {
                flex-direction: column;
                gap: rem(16);
            }

            >p {
                color: $white;
                @include fluid-type(rem(620), rem(1400), rem(12), rem(12));
                font-weight: $FMedium;
                width: 100%;
                text-align: right;
                line-height: rem(19);

                @include mobile-large {
                    text-align: center;
                    font-weight: $FRegular;
                    font-family: $Oswald;
                }

                >a {
                    color: $white;
                    text-decoration: underline;
                }
            }

            .links {
                display: flex;
                gap: rem(24);
                color: $white;
                font-size: rem(13);
                font-weight: $FRegular;
                
                @include mobile-large {
                    justify-content: center;
                }

                >a {
                    width: max-content;
                    color: $white;
                    @include fluid-type(rem(620), rem(1400), rem(13), rem(13));
                    text-decoration: none;
                    font-weight: $FMedium;
                }
            }
        }

    }
}

.darktheme {
    background-color: $green-theme;
}

.lighttheme {
    background-color: $black-theme;
}