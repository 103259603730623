section.page-applications {
    background-color: $white2;

    .accordion-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: rem(20);
    }

    .row {
        padding: rem(20) 0;
        display: flex;
        border-bottom: rem(1) solid $gray3;
        width: 100%;
        margin: 0 auto;

        @include mobile {
            flex-direction: column;
            gap: rem(8);
            padding: rem(16) 0;

        }

        &:first-child {
            border-top: rem(1) solid $gray3;
        }

        &__item {
            @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
            font-weight: $FBold;
            color: $black2;
            line-height: rem(22);
            width: 20%;
            padding: 0;


            @include mobile {
                font-size: rem(16);
                width: 100%;
            }
        }

        &__description {
            @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
            font-weight: $FRegular;
            color: $black2;
            line-height: rem(22);
            width: 80%;
            padding: 0;


            @include mobile {
                width: 100%;
            }
        }
    }

    .button {
        width: rem(280);
        height: rem(72);
        font-weight: $FBold;
        margin: rem(40) 0 rem(48) 0;
        margin: 0 auto;
        margin-top: rem(40);

        @include mobile {
            width: rem(228);
            height: rem(64);

        }

        &:hover {

            &::after {
                background-color: $black-theme;
            }

            &::before {
                background-color: #fff;
            }

            .btn-text {
                &:first-child {
                    transform: translateY(0);
                }

                &:last-child {
                    transform: translateY(rem(-24));
                }
            }

            .circle {
                background-color: #fff;
                transform: scale(1.5);
                transition: all 0.5s ease;
            }

        }

        .btn-text {
            &:first-child {
                color: #fff;
                transform: translateY(rem(24));
            }

            &:last-child {
                color: #fff;
                transform: translateY(0);
            }

        }
    }

    .flow {
        display: flex;
        background-color: #fff;
        padding: rem(48);
        gap: rem(20);
        margin-bottom: rem(96);

        @include tablet-small {
            padding: rem(40);
            margin-bottom: rem(64);
            flex-direction: column;
            align-items: center;

        }

        @include mobile-large {
            padding: rem(40) rem(72);
        }

        &__block {
            display: flex;
            flex-direction: column;
            gap: rem(16);
            width: 100%;
            max-width: rem(164);
            height: rem(160);
            background-color: $green-theme;
            align-items: center;
            position: relative;
            padding: rem(16);

            @include tablet-small {
                max-width: rem(200);
                height: rem(112);
                gap: rem(4);
            }

            .step {
                @include fluid-type(rem(620), rem(1400), rem(20), rem(20));
                font-weight: $FSemibold;
                font-family: $Oswald;
                color: #fff;
                line-height: rem(30);

                >span {
                    @include fluid-type(rem(620), rem(1400), rem(32), rem(32));
                    line-height: rem(47);
                    margin-left: rem(4);
                }
            }

            .text {
                @include fluid-type(rem(620), rem(1400), rem(20), rem(20));
                font-weight: $FBold;
                color: #fff;
                line-height: rem(29);
            }

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: rem(80) solid transparent;
                border-right: rem(80) solid transparent;
                border-bottom: rem(20) solid $green-theme;
                position: absolute;
                top: rem(70);
                right: rem(-90);
                transform: rotate(90deg);

                @include tablet-small {
                    top: inherit;
                    bottom: rem(-20);
                    right: rem(0);
                    transform: rotate(180deg);
                    border-left: rem(100) solid transparent;
                    border-right: rem(100) solid transparent;
                }
            }

            &:last-child {
                max-width: rem(184);

                @include tablet-small {
                    max-width: rem(200);
                }

                &::after {
                    display: none;
                }
            }

        }
    }
}