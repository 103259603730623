section.entryfooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(24);
    justify-content: center;
    background-color: $green-theme;
    padding: rem(48) 0 rem(96) 0;

    @include mobile-large {
        gap: rem(32);
        padding: 0;
        padding-top: rem(40);

    }

    >h2 {
        @include fluid-type(rem(620), rem(1400), rem(53), rem(100));
        font-family: $Oswald;
        font-weight: $FSemibold;
        color: #fff;
        line-height: rem(100);

        @include mobile-large {
            line-height: rem(53);
        }
    }

    .button {
        width: rem(280);
        height: rem(72);
        font-weight: $FBold;

        @include mobile-large {
            width: rem(300);
        }
    }

    &.darktheme {
        background-color: $green-theme;
    }

    &.lighttheme {
        background-color: $black-theme;
    }

}