section.page-vision {

    .description {
        @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
        font-weight: $FRegular;
        color: $black-theme;
        line-height: rem(22);
    }

    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: rem(32);
        padding: rem(40) 0 rem(96) 0;

        @include mobile-large {
            grid-template-columns: 1fr 1fr;
            gap: rem(16);
            padding: rem(40) 0 rem(64) 0;
        }

        &__item {
            padding: rem(32);
            height: rem(189);
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            // gap: rem(20);

            @include mobile-large {
                padding: rem(16) rem(13);
                height: rem(157);
            }

            >h4 {
                @include fluid-type(rem(620), rem(1400), rem(12), rem(14));
                font-weight: $FRegular;
                color: $white;
                line-height: rem(22);
                width: 100%;
                max-width: rem(214);
                border-radius: rem(20);
                box-shadow: inset 0 rem(3) rem(6) rgba(0, 0, 0, 0.2);
                text-align: center;
                padding: rem(8) 0;

                @include mobile-large {
                    line-height: rem(19);
                }
            }

            &--text {
                margin: auto;

                >p {
                    @include fluid-type(rem(620), rem(1400), rem(16), rem(20));
                    font-weight: $FBold;
                    color: $white;
                    line-height: rem(32);
                    text-align: center;
    
                    @include mobile-large {
                        line-height: rem(25);
                    }
                }
            }

           

            &.green-card {
                background-color: $green-theme;

                >h4 {
                    background-color: $green2;
                }
            }

            &.bluelight-card {
                background-color: $blue;

                >h4 {
                    background-color: $blue2;
                }
            }

            &.blue-card {
                background-color: $blue3;

                >h4 {
                    background-color: $blue4;
                }
            }
        }


    }
}