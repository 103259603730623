section.page-work-people {

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .block-01 {
        display: flex;
        flex-direction: column;
        gap: rem(40);
        margin-bottom: rem(80);

        .block-item {
            display: flex;
            gap: rem(40);

            @include mobile-large {
                flex-direction: column;
                gap: rem(24);
                align-items: center;
            }

            >img {
                width: 100%;
                max-width: rem(339);
                height: rem(195);
                object-fit: cover;

                @include mobile {
                    max-width: none;
                    height: 100%;
                }
            }

            .details {
                display: flex;
                flex-direction: column;
                gap: rem(9);
                width: 100%;
                justify-content: center;

                &_title {
                    @include fluid-type(rem(620), rem(1400), rem(20), rem(20));
                    font-weight: $FMedium;
                    color: $black-theme;
                    line-height: rem(32);
                }

                &_text {
                    @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                    font-weight: $FRegular;
                    color: $black-theme;
                    line-height: rem(22);
                }
            }
        }
    }

    .block-02 {
        padding: rem(51) rem(80) rem(64) rem(80);
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $black-theme;
        margin-bottom: rem(80);

        @include mobile-large {
            display: none;
            margin-bottom: rem(64);

        }

        >h3 {
            color: #fff;
        }

        &_content {
            display: flex;
            gap: rem(33);

            @include tablet-small {
                gap: rem(16);
            }

            @include mobile-large {
                display: none;

            }
        }

        &.block-02_mobile {
            width: 100%;
            display: none;
            padding: rem(40) rem(24) rem(48) rem(24);


            @include mobile-large {
                display: flex;
            }

            .scroller {
                width: 100%;

                &__container {
                    gap: rem(24);

                }
            }
        }

    }

    .block02-item {
        display: flex;
        flex-direction: column;
        padding: rem(28) rem(50) rem(24) rem(50);
        border-radius: rem(20);
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        background-color: $white;
        align-items: center;

        @include tablet {
            padding: rem(28) rem(40) rem(24) rem(40);
        }

        @include tablet-small {
            padding: rem(28) rem(24);
        }

        @include mobile-large {
            padding: rem(32) rem(38) rem(24) rem(38);

        }

        >img {
            width: 100%;
            max-width: rem(160);
            margin-bottom: rem(21);

            @include mobile-large {
                max-width: rem(140);
                margin-bottom: rem(16);

            }
        }

        .name {
            @include fluid-type(rem(620), rem(1400), rem(16), rem(20));
            font-weight: $FBold;
            line-height: rem(32);
            color: $black-theme;
            margin-bottom: rem(18);

            @include mobile-large {
                line-height: rem(25);
                margin-bottom: rem(8);

            }
        }

        .details {
            display: flex;
            flex-direction: column;
            gap: rem(2);
            padding-left: rem(8);
            border-left: rem(2) solid $black-theme;
            margin-bottom: rem(26);

            @include mobile-large {
                gap: 0;
                margin-bottom: rem(16);
            }

            >p {
                @include fluid-type(rem(620), rem(1400), rem(13), rem(13));
                font-weight: $FRegular;
                line-height: rem(20);
                color: $black2;
            }
        }

        .button {
            width: rem(180);
            height: rem(56);
            font-weight: $FBold;

        }

        .number {
            position: absolute;
            top: rem(8);
            left: rem(8);
            @include fluid-type(rem(620), rem(1400), rem(40), rem(40));
            font-family: $Oswald;
            font-weight: $FSemibold;
            line-height: rem(64);
            color: $gray2;

            @include mobile-large {
                top: rem(4);
            }
        }

    }

    .block-03 {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(320);
        border-radius: rem(20);
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: rem(96);

        @include mobile-large {
            height: rem(200);
            margin-bottom: rem(64);
            max-width: 90%;
        }

        >img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: transform .5s ease;
            object-fit: cover;
            height: 100%;

            @include mobile-large {
                object-position: 65%;
            }

            @include mobile-small {
                object-position: 70%;
            }
        }

        >p {
            @include fluid-type(rem(620), rem(1400), rem(32), rem(40));
            font-weight: $FBold;
            line-height: rem(64);
            color: #fff;
            z-index: 1;

            @include mobile-large {
                line-height: rem(51);
            }
        }

        &:hover {

            img {
                transform: scale(1.2);
            }
        }
    }
}