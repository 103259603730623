.green-button {
    border-top-left-radius: rem(20);
    border-bottom-right-radius: rem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    @include fluid-type(rem(620), rem(1400), rem(16), rem(16));
    font-weight: $FBold;
    color: $white;

    position: relative;
    overflow: hidden;
    cursor: pointer;
    z-index: 0;

    &.entry-btn {

        &:before {
            background: $green-theme;
        }

        &:after {
            background: #fff;
        }

        &:hover {
            &::after {
                background-color: $green-theme;
            }

            .circle {
                background: #fff;
            }
        }

        .btn-text {
            &:first-child {
                color: #fff;
            }

            &:last-child {
                color: $black-theme;
            }
        }

        .circle {
            background: $black-theme;
        }
    }

    &:hover {
        color: $black-theme;

        &::after {
            background-color: $white;
        }

        &::before {
            background-color: $black-theme;
        }

        .btn-text {
            &:first-child {
                transform: translateY(0);
            }

            &:last-child {
                transform: translateY(rem(-24));
            }
        }

        .circle {
            background-color: $black-theme;
            transform: scale(1.5);
            transition: all 0.5s ease;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 50% 50% 0 0;
        transform: translateY(100%) scaleY(.5);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $green-theme;
        border-radius: 0;
        transform: translateY(0) scaleY(1);
        // transition: all .1s ease;
    }

    >div {
        position: relative;
        width: 100%;
        height: rem(16);
        text-transform: uppercase;
        overflow: hidden;
    }

    .btn-text {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        transition: transform .3s ease;

        &:first-child {
            color: $black-theme;
            transform: translateY(rem(24));
        }

        &:last-child {
            color: #fff;
            transform: translateY(0);
        }

    }

    .circle {
        background: $white;
        width: rem(8);
        height: rem(8);
        margin: auto;
        border-radius: 100%;
        z-index: 1;
        position: absolute;
        right: 11%;
        transition: all 0.5s ease;
    }
}

.black-button {
    border-top-left-radius: rem(20);
    border-bottom-right-radius: rem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    @include fluid-type(rem(620), rem(1400), rem(16), rem(16));
    font-weight: $FBold;
    color: $white;

    position: relative;
    overflow: hidden;
    cursor: pointer;
    z-index: 0;

    &.footer-btn {

        &:before {
            background: #fff;
        }

        &:after {
            background: $black-theme;
        }

        &:hover {
            &::after {
                background-color: #fff;
            }

            .circle {
                background: $black-theme;
            }
        }

        .btn-text {
            &:first-child {
                color: $black-theme;
            }

            &:last-child {
                color: #fff;
            }
        }

        .circle {
            background: #fff;
        }
    }

    &:hover {
        // color: $black-theme;

        &::after {
            background-color: $green-theme;
        }

        &::before {
            background-color: $black-theme;
        }

        .btn-text {
            &:first-child {
                transform: translateY(0);
            }

            &:last-child {
                transform: translateY(rem(-24));
            }
        }

        .circle {
            background-color: $black-theme;
            transform: scale(1.5);
            transition: all 0.5s ease;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $green-theme;
        border-radius: 50% 50% 0 0;
        transform: translateY(100%) scaleY(.5);
        // transition: all .1s ease;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $black-theme;
        border-radius: 0;
        transform: translateY(0) scaleY(1);
        // transition: all .1s ease;
    }

    >div {
        position: relative;
        width: 100%;
        height: rem(16);
        text-transform: uppercase;
        overflow: hidden;
    }

    .btn-text {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        transition: transform .3s ease;

        &:first-child {
            color: #fff;
            transform: translateY(rem(24));
        }

        &:last-child {
            color: #fff;
            transform: translateY(0);
        }

    }

    .circle {
        background: #fff;
        width: rem(8);
        height: rem(8);
        margin: auto;
        border-radius: 100%;
        z-index: 1;
        position: absolute;
        right: 11%;
        transition: all 0.5s ease;
    }
}

.menu-button-white {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    cursor: pointer;

    >span {
        width: rem(60);
        height: rem(2);
        background-color: #fff;

        @include mobile-large {
            width: rem(48);
        }
    }
}

.menu-button-black {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    cursor: pointer;


    >span {
        width: rem(60);
        height: rem(2);
        background-color: $black-theme;

        @include mobile-large {
            width: rem(48);
        }
    }
}

.menuoverlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: $black-theme;
    overflow-y: hidden;
    transition: 0.5s;

    .overlay-content {
        position: relative;
        width: 100%;
        text-align: center;
        padding: rem(24) rem(40);

        @include mobile {
            padding: rem(15);
        }

    }

    >a {
        text-decoration: none;
        @include fluid-type(rem(620), rem(1400), rem(36), rem(36));
        color: #818181;
        display: block;
        transition: 0.3s;

        &:hover {
            color: #f1f1f1;
        }
    }

    .closebtn {
        position: absolute;
        top: rem(44);
        right: rem(40);
        width: rem(60);
        display: flex;
        z-index: 1;
        height: rem(16);
        align-items: center;

        @include mobile-large {
            width: rem(48);
            top: rem(24);
            right: rem(16);
        }

        span {
            width: rem(60);
            height: rem(2);
            background-color: #fff;
            position: absolute;

            @include mobile-large {
                width: rem(48);
            }

            &:first-child {
                transform: rotate(15deg);

                @include mobile-large {
                    transform: rotate(20deg);
                }
            }

            &:last-child {
                transform: rotate(-15deg);

                @include mobile-large {
                    transform: rotate(-20deg);
                }
            }
        }
    }
}

.graph {
    position: relative;
    width: rem(250);
    height: rem(250);
    transform: rotate(-90deg);

    @include mobile-large {
        width: rem(125);
        height: rem(125);
    }

    circle {
        position: relative;
        fill: none;
        stroke-width: 25;
        stroke: $blue;
        stroke-dasharray: 700;
        stroke-dashoffset: 0;

        @include mobile-large {
            stroke-width: 12;
            stroke-dasharray: 435;
        }
    }

    .line {
        // stroke-dashoffset: calc(685 - (685 * 70) / 100);
        stroke-dashoffset: 680;
        stroke: $green-theme;
        stroke-linecap: round;


        @include mobile-large {
            stroke-dashoffset: 415;

        }
    }

    .animateLine {
        animation: circleAnim 1s forwards;

        @include mobile-large {
            animation: circleAnimMobile 1s forwards;
        }
    }

    @keyframes circleAnim {
        to {
            stroke-dashoffset: 225;
        }
    }

    @keyframes circleAnimMobile {
        to {
            stroke-dashoffset: 202;
        }

    }

    &.graph-pc,
    &.graph-pc2 {
        display: flex;

        @include mobile-large {
            display: none;
        }
    }

    &.graph-mobile {
        display: none;

        @include mobile-large {
            display: flex;
        }
    }
}

.graph_text {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: $black2;

    >p {
        @include fluid-type(rem(620), rem(1400), rem(12), rem(25));
        font-weight: $FBold;
        line-height: rem(30);

        @include mobile-large {
            line-height: rem(14);
        }
    }

    &--blue {
        color: $blue;
    }

    &--green {
        color: $green-theme;
    }
}

.breadcrumbs {
    display: flex;
    gap: rem(8);
    align-items: center;

    >a {
        @include fluid-type(rem(620), rem(1400), rem(12), rem(12));
        font-weight: $FMedium;
        line-height: rem(20);
        text-decoration: none;
    }

    .gray-text {
        color: $gray2;
    }

    .arrow {
        width: rem(5);
        height: rem(10);
    }
}

.breadcrumbs-light {
    .page-color {
        color: $black-theme;
    }
}

.breadcrumbs-dark {
    .page-color {
        color: #fff;
    }
}

.popout {
    animation: popout 1s ease;
    -webkit-animation: popout 1s ease;
}

@keyframes popout {
    from {
        transform: scale(0)
    }

    80% {
        transform: scale(1.2)
    }

    to {
        transform: scale(1)
    }
}

@-webkit-keyframes popout {
    from {
        -webkit-transform: scale(0)
    }

    80% {
        -webkit-transform: scale(1.2)
    }

    to {
        -webkit-transform: scale(1)
    }
}

//scroller
.scroller {
    overflow-x: scroll;
    overflow: hidden;
    // white-space: nowrap;
    width: 100%;
    margin-bottom: rem(48);

    &__container {
        display: flex;
    }


}

.slider {
    width: 100%;
    height: rem(2);
    width: rem(528);
    background: #fff;
    margin-bottom: rem(10);

    @include mobile-large {
        max-width: rem(345);

    }
}

input::-webkit-slider-thumb {
    background: transparent;
    background-image: url(../../src/images/interview/slider_icon.webp);
    background-size: contain;
    width: rem(48);
    height: rem(48);
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
}

//scroller

.accordion {

    .accordion-item {
        margin-bottom: rem(24);
        border: none;
        border-radius: rem(20);
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        overflow: hidden;

        @include mobile-large {
            margin-bottom: rem(16);
        }

        .accordion-header {

            .accordion-button {
                background-color: #fff;
                box-shadow: none;
                padding: rem(26) rem(48);
                align-items: center;

                @include mobile-large {
                    padding: rem(23) rem(16);

                }

                .question__icon {
                    @include fluid-type(rem(620), rem(1400), rem(20), rem(25));
                    font-weight: $FSemibold;
                    font-family: $Oswald;
                    color: $green-theme;
                    line-height: rem(40);
                    margin-right: rem(16);

                    @include mobile-large {
                        line-height: rem(32);
                    }
                }

                .question__text {
                    @include fluid-type(rem(620), rem(1400), rem(16), rem(20));
                    font-weight: $FBold;
                    color: $black2;
                    line-height: rem(32);

                    @include mobile-large {
                        line-height: rem(25);
                    }
                }

                &::after {
                    content: "";
                    background-image: url(../../src/images/plus_icon.webp);
                    background-position: center;
                    width: rem(40);
                    height: rem(40);
                    border-radius: 50%;
                    background-color: $green-theme;
                    color: #fff;
                    font-size: rem(20);
                    transition: all 0.5s;

                    @include mobile-large {
                        width: rem(24);
                        height: rem(24);
                        background-size: rem(14);
                    }
                }


                &:not(.collapsed) {
                    .question__icon {
                        color: $black2;
                    }
                }

                &:not(.collapsed)::after {
                    content: "";
                    background-image: url(../../src/images/minus_icon.webp);
                    background-position: center;
                    width: rem(40);
                    height: rem(40);
                    border-radius: 50%;
                    background-color: $black-theme;
                    color: #fff;
                    font-size: rem(22);

                    @include mobile-large {
                        width: rem(24);
                        height: rem(24);
                        background-size: rem(14);
                    }
                }
            }
        }

        .accordion-body {
            display: flex;
            gap: rem(16);
            align-items: center;
            padding: 0 rem(48);
            padding-bottom: rem(24);

            @include mobile-large {
                align-items: flex-start;
                padding: 0 rem(16) rem(20) rem(16);
            }

            .answer__icon {
                @include fluid-type(rem(620), rem(1400), rem(20), rem(25));
                font-weight: $FSemibold;
                font-family: $Oswald;
                color: $green-theme;
                line-height: rem(40);

                @include mobile-large {
                    line-height: rem(17);

                }
            }

            .answer__text {
                @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                font-weight: $FRegular;
                color: $black2;
                line-height: rem(22);
            }
        }
    }
}

.form-steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: min(rem(436), 80%);
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: rem(2);
        background: $gray2;
        z-index: -1;
    }

    .step {
        width: rem(32);
        height: rem(32);
        background: $gray2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &.active {
            background-color: $green-theme;
        }
    }

}

.tabs {
    display: flex;
    gap: rem(16);
    width: 100%;

    .tab-item {
        border-radius: rem(20);
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 50%;
        padding: rem(16);
        background-color: $white2;
        box-shadow: 0 rem(3) rem(6) rgba(0, 0, 0, 0.2);
        cursor: pointer;

        >p {
            @include fluid-type(rem(620), rem(1400), rem(16), rem(25));
            font-weight: $FBold;
            color: $gray2;
            line-height: rem(40);
            text-align: center;

            @include mobile-large {
                line-height: rem(25);
            }
        }

        &.active-tab {
            box-shadow: inset 0 rem(3) rem(6) rgba(0, 0, 0, 0.2);

            >p {
                color: $black-theme;
            }
        }
    }
}

.tabs__container {
    padding: rem(64) rem(16) rem(80) rem(16);
    background-color: $white2;
    margin-top: rem(8);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet-small {
        padding: rem(40) rem(16) rem(48) rem(16);
    }

    .tab-visibility {
        display: none;
    }

    .tab-form-active {
        display: flex;
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: rem(40);

    @include mobile-large {
        gap: rem(40);
    }

    .item_form {
        width: 100%;
        display: flex;
        align-items: center;
        gap: rem(50);

        @include mobile-large {
            flex-direction: column;
            gap: rem(24);
            position: relative;

        }

        .field {
            display: flex;
            gap: rem(8);
            width: 100%;
            max-width: rem(150);
            align-items: center;
            align-self: baseline;
            padding-top: rem(12);
            position: relative;

            @include mobile-large {
                max-width: none;
            }

            &.field_rb {
                padding-top: 0;
            }

            .field_text {
                @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                font-weight: $FBold;
                color: $black2;
                line-height: rem(22);

                @include mobile-large {
                    font-size: rem(16);
                }
            }

            .obs {
                position: absolute;
                bottom: rem(-25);
                left: 0;
                @include fluid-type(rem(620), rem(1400), rem(12), rem(12));
                font-weight: $FRegular;
                color: $black2;
                line-height: rem(24);
            }
        }

        .required {
            @include fluid-type(rem(620), rem(1400), rem(11), rem(11));
            font-weight: $FRegular;
            color: #fff;
            line-height: rem(17);
            border-radius: rem(9);
            padding: 0 rem(8);
            background-color: $red;
        }

        .optional {
            @include fluid-type(rem(620), rem(1400), rem(11), rem(11));
            font-weight: $FRegular;
            color: #fff;
            line-height: rem(17);
            border-radius: rem(9);
            padding: 0 rem(8);
            background-color: $gray4;
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: rem(8);
            width: 100%;

            .rb_items {
                display: flex;
                gap: rem(40);
                width: 100%;
            }

            .cb_item {
                align-self: center;

                input[type=checkbox] {
                    -webkit-appearance: checkbox;
                }

                .cbLabel {
                    @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                    font-weight: $FRegular;
                    color: $black2;
                    line-height: rem(22);

                    >a {
                        font-weight: $FMedium;
                        color: $gray4;
                        text-decoration: underline;
                    }

                    .required {
                        margin-left: rem(8);
                    }
                }
            }

            .input_item {
                width: 100%;
                padding: rem(14) rem(24);
                box-sizing: border-box;
                background-color: #fff;
                display: flex;

                input {
                    width: 100%;
                    @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                    font-weight: $FRegular;
                    color: $black2;
                    line-height: rem(22);


                    &::placeholder {
                        color: $gray4;
                    }
                }

                >span {
                    @include fluid-type(rem(620), rem(1400), rem(16), rem(16));
                    font-weight: $FBold;
                    color: $black2;
                    line-height: rem(25);
                }

                textarea {
                    width: 100%;
                    border: none;
                    overflow: auto;
                    outline: none;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    resize: none;
                    @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                    font-weight: $FRegular;
                    color: $black2;
                    line-height: rem(22);

                    &::placeholder {
                        color: $gray4;
                    }
                }

                &.small_item {
                    width: 50%;
                    margin-bottom: rem(16)
                }

                &.inputFile {
                    display: flex;
                    gap: rem(40);
                    align-items: center;
                    background-color: transparent;
                    padding: 0;

                    @include mobile-large {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: rem(8);
                    }

                    >input {
                        display: none
                    }

                    .filename {
                        @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                        font-weight: $FRegular;
                        color: $black2;
                        line-height: rem(22);
                    }

                    .selectfile-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        max-width: rem(225);
                        height: rem(40);
                        background-color: $green-theme;
                        @include fluid-type(rem(620), rem(1400), rem(14), rem(16));
                        font-weight: $FRegular;
                        line-height: rem(22);
                        color: #fff;
                        cursor: pointer;
                        border-radius: rem(10);
                        position: relative;

                        &::before {
                            content: "";
                            width: rem(14);
                            height: rem(14);
                            background-image: url(../../src/images/upload_icon.webp);
                            background-size: cover;
                            position: absolute;
                            margin: auto 0;
                            left: 10%;
                        }
                    }
                }

            }

            .obs {
                @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                font-weight: $FRegular;
                color: $black2;
                line-height: rem(22);
                margin-top: rem(24);
            }

            .obs2 {
                align-self: center;
                @include fluid-type(rem(620), rem(1400), rem(12), rem(12));
                font-weight: $FRegular;
                color: $black2;
                line-height: rem(20);
            }
        }
    }

    .error {
        color: $red;
        @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
        font-weight: $FMedium;
        line-height: rem(22);

        @include mobile-large {
            margin-left: rem(16);
        }

    }

    #entryagreement-error,
    #internshipagreement-error {
        align-self: center;
    }


}

.button-form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(280);
    height: rem(72);
    align-self: center;
    background-color: $green-theme;
    border-radius: rem(20);
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    border: none;

    @include mobile-large {
        width: rem(228);
        height: rem(64);
    }

    >input {
        @include fluid-type(rem(620), rem(1400), rem(16), rem(16));
        font-weight: $FBold;
        color: #fff;
        line-height: rem(25);
        width: 100%;
        height: 100%;
    }

    &.back-button {
        background-color: #fff;
        border: rem(2) solid $green-theme;
        text-decoration: none;

        >input {
            color: $green-theme;
        }
    }
}

//custom radio button
.rbLabel {
    @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
    font-weight: $FRegular;
    color: $black2;
    line-height: rem(22);
    display: block;
    position: relative;
    padding-left: rem(30);
    cursor: pointer;
    -webkit-user-select: none;

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: rem(20);
        width: rem(20);
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid $gray4;
        box-sizing: border-box;

        &::after {
            content: "";
            position: absolute;
            display: none;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: rem(14);
            height: rem(14);
            border-radius: 50%;
            background: $green-theme;
        }
    }

    input:checked~.checkmark:after {
        display: block;
    }

}

//custom checkbox 
.cbLabel {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;

    >input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: rem(20);
        width: rem(20);
        background-color: #fff;
        border: $gray4;
        box-sizing: border-box;

        &::after {
            content: "";
            position: absolute;
            display: none;

            left: rem(7);
            top: rem(4);
            width: rem(5);
            height: rem(10);
            border: solid #fff;
            border-width: 0 rem(3) rem(3) 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    input:checked~.checkmark {
        background-color: $green-theme;
        border: none;
    }

    input:checked~.checkmark:after {
        display: block;
    }
}