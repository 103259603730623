body {
    font-family: $NotoSansJP;
    @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
    background-color: $black-theme;
}

.inner {
    width: min(1080px, 90%);

    @include mobile {
        width: 100%;
        padding: rem(15);
    }
}

section.page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .container {
        margin: rem(130) 0 rem(17) 0;
        z-index: 1;

        @include mobile-large {
            margin: rem(112) 0 rem(25) 0;
        }

        .title {
            @include fluid-type(rem(620), rem(1400), rem(53), rem(100));
            font-weight: $FSemibold;
            line-height: rem(100);
            font-family: $Oswald;
            margin-bottom: rem(20);

            @include mobile-large {
                line-height: rem(53);

            }
        }

        .subtitle {
            @include fluid-type(rem(620), rem(1400), rem(16), rem(20));
            font-weight: $FBold;
            line-height: rem(32);
            margin-bottom: rem(96);

            @include mobile-large {
                line-height: rem(25);
                margin-bottom: rem(64);

            }
        }

        h3 {
            @include fluid-type(rem(620), rem(1400), rem(32), rem(40));
            font-weight: $FBold;
            line-height: rem(64);
            margin-bottom: rem(41);

            @include mobile-large {
                line-height: rem(51);
                margin-bottom: rem(25);

            }
        }
    }

    .bg-page {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 0;
    }
}

section.page-dark {
    background-color: $black-theme;

    .container {

        .title {
            color: #fff;
        }

        .subtitle {
            color: #fff;
        }

        >h3 {
            color: #fff;
        }
    }
}

section.page-light {
    background-color: #fff;

    .container {

        .title {
            color: $black-theme;
        }

        .subtitle {
            color: $black-theme;
        }

        >h3 {
            color: $black-theme;
        }
    }
}

.sp-mode {
    display: none !important;

    @include mobile {
        display: flex !important;
    }
}

.pc-mode {
    display: flex !important;

    @include mobile {
        display: none !important;
    }
}