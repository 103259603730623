$NotoSansJP: 'Noto Sans JP', sans-serif;
$Oswald: 'Oswald', sans-serif;

// Font Weight
$FRegular: 400;
$FMedium: 500;
$FSemibold: 600;
$FBold: 700;


// Colors
$white: #F5F5F5;
$white2: #F2F2F2;
$black-theme: #212121;
$black2: #333333;
$green-theme: #00BCBC;
$green2: #00A4A4;
$gray: #E9E9E9;
$gray2: #B9B9B9;
$gray3: #DEDEDE;
$gray4: #9A9A9A;
$blue: #0090BC;
$blue2: #007DA4;
$blue3: #3980C2;
$blue4: #326FA9;
$red: #EF0000;
