html {
    font-size: inherit;
}

section.page-about {
    overflow: hidden;


    .blocks {
        display: flex;
        flex-direction: column;
        gap: rem(32);
        margin-bottom: rem(80);

        @include mobile-large {
            margin-bottom: rem(64);
        }

        .blocks-linear__item {
            padding-bottom: rem(29);
        }

        &-messy {
            gap: rem(32);
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: rem(246);
        }

        &-linear {
            display: flex;
            gap: rem(30);

            @include mobile {
                gap: rem(15);
            }

            &__item {
                width: 100%;
                max-width: rem(339);
                height: rem(339);
                background-color: $white2;
                padding-bottom: rem(29);
                justify-content: space-between;

                @include mobile-large {
                    max-width: 100%;
                    height: auto;
                }

                @include mobile {
                    max-width: rem(165);
                    height: rem(180);
                    gap: rem(15);
                }

                &.big-block {
                    max-width: rem(710);
                    padding: rem(24);
                    justify-content: space-between;
                    display: flex;
                    flex-direction: column;

                    @include mobile-large {
                        height: auto;
                    }
                }

                &.graph-block {
                    justify-content: center;
                }

                .img2 {
                    width: 100%;
                    max-width: rem(259);
                }

                .position {
                    @include fluid-type(rem(620), rem(1400), rem(40), rem(40));
                    font-weight: $FBold;
                    color: $green-theme;
                    line-height: rem(40);
                    gap: rem(4);

                    >span {
                        @include fluid-type(rem(620), rem(1400), rem(48), rem(48));
                        font-weight: $FSemibold;
                        line-height: rem(76);
                        font-family: $Oswald
                    }
                }

                .texts {
                    @include fluid-type(rem(620), rem(1400), rem(32), rem(40));
                    font-weight: $FBold;
                    color: $black2;
                    line-height: rem(64);

                    .small-text {
                        @include fluid-type(rem(620), rem(1400), rem(25), rem(25));
                        line-height: rem(35);
                    }
                }

                .bigcontent {
                    display: flex;
                    padding: 0 rem(8);
                    justify-content: space-between;

                    @include mobile-large {
                        flex-direction: column;
                        padding: 0;
                    }

                    .position {
                        @include fluid-type(rem(620), rem(1400), rem(25), rem(25));
                        font-weight: $FBold;
                        color: $green-theme;
                        line-height: rem(40);
                        gap: rem(4);

                        >span {
                            @include fluid-type(rem(620), rem(1400), rem(48), rem(48));
                            font-weight: $FSemibold;
                            line-height: rem(76);
                            font-family: $Oswald
                        }
                    }

                    .left {
                        display: flex;
                        flex-direction: column;

                        &__item {
                            display: flex;
                            align-items: center;
                            gap: rem(24);

                            @include mobile {
                                gap: rem(8);
                            }
                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;
                        gap: rem(8);
                        margin-top: rem(18);

                        @include mobile-large {
                            padding: 0 rem(24);
                        }

                        >p {
                            @include fluid-type(rem(620), rem(1400), rem(20), rem(20));
                            font-weight: $FBold;
                            color: $green-theme;
                            line-height: rem(32);
                            margin-left: rem(-23);
                        }

                        &__list {
                            list-style: disc;
                            @include fluid-type(rem(620), rem(1400), rem(16), rem(16));
                            font-weight: $FBold;
                            color: $black2;
                            line-height: rem(25);
                            display: flex;
                            flex-direction: column;
                            gap: rem(8);
                        }
                    }
                }

                .smallcontent {
                    display: flex;
                    flex-direction: column;

                    &__item {
                        display: flex;
                        align-items: center;
                        gap: rem(24);

                        .position {
                            @include fluid-type(rem(620), rem(1400), rem(13), rem(40));

                            @include mobile-large {
                                line-height: rem(21);
                            }

                            >span {
                                @include fluid-type(rem(620), rem(1400), rem(20), rem(48));

                                @include mobile-large {
                                    line-height: rem(32);
                                }
                            }
                        }

                        .texts {
                            @include fluid-type(rem(620), rem(1400), rem(20), rem(40));

                            @include mobile-large {
                                line-height: rem(32);

                            }


                        }
                    }
                }
            }
        }

        &-wrap {
            flex-wrap: wrap;
            justify-content: center;

            .item {

                @include mobile-large {
                    padding: rem(8);
                    gap: rem(8);
                    justify-content: center;
                }

                .big__title {

                    @include mobile-large {
                        height: rem(43);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    
                }

            }
        }

    }

    .item {
        background-color: $white2;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: rem(24) 0 rem(22) 0;

        @include mobile-large {
            padding: rem(16);
        }

        &.item-1 {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;

            .item__title {
                margin-top: rem(8);
            }

            @include tablet-small {
                padding: 0 rem(15);
            }
        }

        &.item-2 {
            .num {
                margin-top: rem(-22);
            }
        }

        &.item-4 {
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 2;
            grid-row-end: 4;

        }

        &.item-15 {
            gap: rem(24);

            .numbers {
                margin-top: rem(-12);
            }
        }

        &.item-full {
            height: 100%;
        }

        .polygon {
            position: absolute;
            width: rem(34);
            height: rem(30);

            @include mobile-large {
                width: rem(24);
                height: rem(21);
            }

            &.bg1 {
                top: rem(20);
                left: rem(20);

                @include mobile-large {
                    top: rem(16);
                    left: rem(16);
                }
            }

            &.bg2 {
                bottom: rem(20);
                right: rem(20);

                @include mobile-large {
                    bottom: rem(16);
                    right: rem(16);
                }
            }
        }

        &__title {
            @include fluid-type(rem(620), rem(1400), rem(12), rem(20));
            font-weight: $FMedium;
            color: $black2;
            line-height: rem(32);
            padding: rem(8) rem(24);
            border-radius: rem(29);
            box-shadow: inset 0 rem(3) rem(6) rgba(0, 0, 0, 0.2);
            background-color: $gray;
            margin: 0 auto;
            width: max-content;
            text-align: center;

            @include tablet-small {
                padding: rem(8) rem(18);

            }

            @include mobile-large {
                line-height: rem(20);
                padding: rem(8) rem(18);
            }

            @include mobile {
                padding: rem(4) rem(10);
                height: rem(35);
                width: rem(133);
                display: flex;
                align-items: center;
                justify-content: center;

            }

            &.big__title {
                min-width: rem(150);
            }

            &.small__title {
                min-width: rem(133);
            }
        }

        .numbers {
            display: flex;
            gap: rem(32);

            @include mobile-large {
                align-items: flex-end;
            }

            .num {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: rem(8);

                @include mobile-large {
                    align-items: center;
                }


                .text {
                    @include fluid-type(rem(620), rem(1400), rem(13), rem(14));
                    font-weight: $FBold;
                    color: $black2;
                    line-height: rem(22);

                    @include mobile-large {
                        line-height: rem(20);
                    }
                }

                .text2 {
                    @include fluid-type(rem(620), rem(1400), rem(25), rem(25));
                    font-weight: $FBold;
                    color: $black2;
                    display: flex;
                    align-items: flex-end;
                    gap: rem(8);

                    @include mobile-large {
                        margin-bottom: rem(-5);
                    }
                }

                .text3 {
                    @include fluid-type(rem(620), rem(1400), rem(16), rem(16));
                    font-weight: $FBold;
                    color: $black2;
                    display: flex;
                    align-items: flex-end;
                    gap: rem(8);
                    text-align: center;

                    @include mobile-large {
                        margin-bottom: rem(-5);
                    }
                }

                .count-size {
                    @include fluid-type(rem(620), rem(1400), rem(64), rem(64));
                    font-family: $Oswald;
                    font-weight: $FSemibold;
                    color: $green-theme;
                }
            }

            .colon {
                @include fluid-type(rem(620), rem(1400), rem(40), rem(40));
                font-weight: $FSemibold;
                color: $gray2;
                line-height: rem(64);
                font-family: $Oswald;
            }

            .bigblock {
                display: flex;
                flex-direction: column;
                gap: rem(28);
                align-items: baseline;
                margin-top: rem(32);

                @include mobile-large {
                    margin-top: rem(8);
                    gap: rem(12);
                }

                &__item {
                    display: flex;
                    flex-direction: column;
                    gap: rem(16);

                    @include mobile-large {
                        gap: rem(8);
                    }

                    &.bigtext {
                        gap: rem(12);

                        @include mobile-large {
                            gap: rem(5);
                        }

                        .bigblock__item--num {

                            >p {
                                @include fluid-type(rem(620), rem(1400), rem(25), rem(32));
                                display: flex;
                                align-items: flex-end;
                                gap: rem(8);
                            }

                            .count-size {
                                @include fluid-type(rem(620), rem(1400), rem(64), rem(85));
                            }
                        }
                    }

                    .text {
                        @include fluid-type(rem(620), rem(1400), rem(12), rem(14));
                        font-weight: $FBold;
                        color: #fff;
                        padding: rem(4) rem(16);
                        background-color: $black-theme;
                        width: max-content;

                        @include mobile-large {
                            padding: rem(2) rem(16);
                        }
                    }

                    &--num {
                        display: flex;
                        gap: rem(10);

                        >p {
                            @include fluid-type(rem(620), rem(1400), rem(16), rem(25));
                            font-weight: $FBold;
                            color: $black-theme;
                            display: flex;
                            align-items: flex-end;
                            gap: rem(8);
                        }

                        .count-size {
                            @include fluid-type(rem(620), rem(1400), rem(40), rem(48));
                            font-weight: $FSemibold;
                            color: $green-theme;
                            font-family: $Oswald;
                        }
                    }
                }
            }
        }

        .img8 {
            background-image: url(../../src/images/about/ab8.webp);
            background-repeat: no-repeat;
            background-size: contain;
            width: 100%;
            max-width: rem(443);
            height: rem(338);
            margin-top: rem(33);
            position: relative;

            @include tablet-small {
                margin: auto;
            }

            @include mobile-large {
                max-width: rem(299);
                height: rem(236);
                margin-top: rem(16);

            }

            .porcent {
                position: absolute;
                @include fluid-type(rem(620), rem(1400), rem(25), rem(25));
                font-family: $Oswald;
                font-weight: $FSemibold;
                color: #fff;
                line-height: rem(40);

                >span {
                    @include fluid-type(rem(620), rem(1400), rem(32), rem(40));
                    line-height: rem(64);
                }

                &.porc1 {
                    left: calc(50% - rem(34));
                    top: 10%;
                }

                &.porc2 {
                    left: 27%;
                    top: 23%;
                }

                &.porc3 {
                    right: calc(50% - rem(60));
                    bottom: 20%;

                    @include tablet-small {
                        right: calc(55% - rem(60));
                        bottom: 23%;
                    }
                }
            }
        }

        .img {
            width: 100%;
            max-width: rem(166);
            margin-top: rem(10);
        }

        &.item__mobile {
            .item__content {
                display: flex;
                gap: rem(28);
                align-items: center;
            }

            .img {
                max-width: rem(112);
            }

            .img2 {
                max-width: rem(78);
                margin-top: rem(10);
            }

            .img3 {
                max-width: rem(117);
            }
        }
    }

    .block-pc {
        display: flex;

        @include mobile-large {
            display: none;
        }
    }

    .about-slider {
        width: 100%;
        display: flex;
        max-width: rem(345);
        margin: 0 auto;
        margin-bottom: rem(25);

        @include mobile-large {
            margin-bottom: rem(64);
        }

        .slider_item {
            width: 100%;
            height: rem(345);
            color: #fff;
            gap: rem(16);
            display: flex;
            flex-direction: column;

            &.slider_grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: rem(16);

                .item__mobile {
                    padding: rem(8) rem(16);
                }

                .numbers {
                    display: flex;
                    gap: rem(32);

                    @include mobile-large {
                        align-items: flex-end;
                    }

                    .num {
                        display: flex;
                        flex-direction: column;

                        @include mobile-large {
                            align-items: center;
                        }

                        .text2 {
                            @include fluid-type(rem(620), rem(1400), rem(16), rem(25));
                            font-weight: $FBold;
                            color: $black2;
                            display: flex;
                            align-items: flex-end;
                            gap: rem(4);
                        }

                        .count-size {
                            @include fluid-type(rem(620), rem(1400), rem(48), rem(64));
                            font-family: $Oswald;
                            font-weight: $FSemibold;
                            color: $green-theme;
                            margin-bottom: rem(-5);
                        }
                    }
                }
            }
        }

        .slick-dots {
            >li {
                >button {
                    &::before {
                        opacity: 0.8;
                        font-size: rem(45);
                    }
                }
            }

            .slick-active {
                >button {
                    &::before {
                        opacity: 1;
                        color: #fff;
                    }
                }
            }
        }
    }

    .block-mobile {
        display: none;

        @include mobile-large {
            display: flex;
        }
    }

    // .special-slider {
    //     width: 100%;
    //     display: flex;
    //     margin-bottom: rem(32);
    //     z-index: 1;

    //     img {
    //         width: 100%;
    //         max-width: rem(485);
    //         height: rem(280);
    //         object-fit: cover;
    //         margin-left: rem(32);
    //     }
    // }

    .flex-pc {
        display: flex;
         
        @include tablet-small {
            display: none;
        }
    }

    .grid-mobile {
        display: none;
        grid-template-columns: 1fr 1fr;
        gap: rem(32);
        width: 100%;


        @include tablet-small {
            display: grid;
            max-width: rem(710);
        }

        @include mobile {
            gap: rem(16);
        }

        .item {
            @include tablet-small {
                max-width: none;
            }
        }
    }
}