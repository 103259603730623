section.page-interview {
    position: relative;
    overflow: hidden;

    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .section-01 {
        display: flex;
        flex-direction: column;
        position: relative;

        >h3 {
            color: #fff;

            @include mobile-large {}
        }

        .subtitle {
            @include mobile-large {
                margin-bottom: rem(16);
            }
        }

        .img_mobile {
            width: 100%;
            max-width: rem(334);
            border-top-left-radius: rem(20);
            margin-left: rem(31);
            display: none;
            margin-bottom: rem(16);

            @include mobile-large {
                display: block;
                border-bottom-right-radius: rem(20);
                max-width: none;
                margin-left: 0;
            }

            @include mobile {
                margin-left: rem(31);
            }

            @include mobile-small {
                display: block;
                border-bottom-right-radius: 0;
            }

        }

        .details {
            display: flex;
            flex-direction: column;
            margin-bottom: rem(112);

            @include tablet-small {
                margin-bottom: rem(32);
            }

            @include mobile-large {
                margin-bottom: rem(40);
            }

            >h4 {
                @include fluid-type(rem(620), rem(1400), rem(20), rem(25));
                font-weight: $FBold;
                line-height: rem(40);
                color: #fff;
                margin-bottom: rem(16);
                padding-bottom: rem(16);
                border-bottom: rem(1) solid #fff;
                width: rem(180);

                @include mobile-large {
                    line-height: rem(32);
                    padding-bottom: rem(8);
                    margin-bottom: rem(13);
                }
            }

            &__text {
                @include fluid-type(rem(620), rem(1400), rem(13), rem(14));
                font-weight: $FRegular;
                line-height: rem(22);
                color: #fff;

                @include mobile-large {
                    line-height: rem(20);
                }

            }

            &__name {
                @include fluid-type(rem(620), rem(1400), rem(16), rem(20));
                font-weight: $FMedium;
                line-height: rem(32);
                color: #fff;

                @include mobile-large {
                    line-height: rem(25);
                }
            }

            &_text {
                display: flex;
                flex-direction: column;
                gap: rem(8);

                @include mobile-large {
                    flex-direction: row;
                    align-items: center;
                    gap: rem(24);
                }
            }
        }

        .horizontal-scroll {
            overflow-x: hidden;
            white-space: nowrap;
            position: absolute;
            bottom: rem(70);

            @include mobile-large {
                bottom: rem(36);
            }

            @include mobile {
                bottom: rem(52);

            }

            .horizontal__scroller {
                overflow-x: hidden;
                white-space: nowrap;
                width: 100%;
                // margin-bottom: rem(48);
                display: flex;
                transition: transform 0.3s ease-in-out;

                @include mobile-large {
                    margin-bottom: rem(32);
                }

                // @include pc-large {
                //     width: 75vw;
                // }

                // @include pc {
                //     width: 83vw;
                // }

                // @include tablet {
                //     width: 88vw;
                // }



                .scroller__item {
                    width: 100%;
                    max-width: rem(284);
                    height: rem(172);
                    margin: 0 rem(12);
                    background-color: $white2;
                    padding: rem(16) rem(24);
                    display: flex;
                    flex-direction: column;

                    @include mobile-large {
                        min-width: rem(208);
                        padding: rem(16);

                    }

                    >.time {
                        @include fluid-type(rem(620), rem(1400), rem(20), rem(25));
                        font-weight: $FSemibold;
                        font-family: $Oswald;
                        color: $black-theme;
                        line-height: rem(40);
                        padding: rem(4) 0;
                        border-radius: rem(29);
                        box-shadow: inset 0 rem(3) rem(6) rgba(0, 0, 0, 0.2);
                        background-color: $gray;
                        // margin-bottom: rem(16);
                        text-align: center;
                        width: rem(236);

                        @include mobile-large {
                            line-height: rem(32);
                            // margin-bottom: rem(8);
                            width: rem(160);
                        }
                    }

                    .content {
                        justify-content: space-between;
                        display: flex;
                        flex-direction: column;
                        margin: auto;

                        >h4 {
                            @include fluid-type(rem(620), rem(1400), rem(20), rem(20));
                            font-weight: $FBold;
                            color: $black2;
                            line-height: rem(32);
                            // margin-bottom: rem(8);
                            text-align: center;

                            @include mobile-large {
                                // margin-bottom: rem(4);
                            }
                        }

                        >.text {
                            @include fluid-type(rem(620), rem(1400), rem(12), rem(14));
                            font-weight: $FRegular;
                            color: $black-theme;
                            line-height: rem(22);
                            text-align: center;

                            @include mobile-large {
                                line-height: rem(19);
                            }

                        }
                    }


                }

            }
        }

        .slider {
            margin-top: rem(240);
        }
    }

    .section-02 {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(80);
        width: 100%;

        @include mobile-large {
            gap: rem(48);
        }

        .blocks {
            display: flex;
            justify-content: flex-end;
            gap: rem(80);
            margin: rem(-60) 0 rem(20) 0;

            @include tablet-small {
                gap: rem(32);
                margin: 0;
            }

            @include mobile-large {
                gap: rem(16);
            }

            @include mobile-large {
                flex-direction: column;
            }

            >.img {
                object-fit: contain;
                width: 100%;
                max-width: rem(560);

                @include tablet {
                    max-width: rem(464);
                }

                @include tablet-small {
                    max-width: rem(416);
                }


            }

            &__imgRight {
                margin-left: rem(-100);

                @include mobile-large {
                    margin-left: 0;
                }
            }

            &__imgLeft {
                margin-right: rem(-100);
            }

            &__detail {
                display: flex;
                flex-direction: column;
                gap: rem(64);
                width: 100%;

                @include mobile-large {
                    gap: rem(48);
                }

                .question-item {
                    display: flex;
                    flex-direction: column;

                    >h4 {
                        @include fluid-type(rem(620), rem(1400), rem(22), rem(22));
                        font-weight: $FSemibold;
                        font-family: $Oswald;
                        line-height: rem(22);
                        color: $green-theme;
                        margin-bottom: rem(8);
                        display: flex;
                        gap: rem(4);
                        align-items: baseline;

                        >span {
                            @include fluid-type(rem(620), rem(1400), rem(40), rem(40));
                            line-height: rem(64);
                        }
                    }

                    >.question {
                        @include fluid-type(rem(620), rem(1400), rem(20), rem(25));
                        font-weight: $FBold;
                        line-height: rem(40);
                        color: #fff;
                        padding: rem(8) rem(24);
                        background-color: $green-theme;
                        margin-bottom: rem(8);
                        width: 100%;
                        max-width: max-content;

                        @include mobile-large {
                            line-height: rem(32);
                        }
                    }

                    >.text {
                        @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                        font-weight: $FRegular;
                        line-height: rem(22);
                        color: $black-theme;
                        margin-top: rem(17);
                    }
                }
            }

            &.blocks-reverse {
                @include mobile-large {
                    flex-direction: column-reverse;
                }
            }
        }

        .green-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: rem(48) rem(16) rem(64) rem(16);
            gap: rem(33);
            background-color: $green-theme;

            @include mobile-large {
                max-width: 90%;
                padding: rem(32) rem(16) rem(32) rem(16);
                gap: rem(17);
            }

            >h3 {
                @include fluid-type(rem(620), rem(1400), rem(25), rem(40));
                font-weight: $FBold;
                line-height: rem(64);
                color: #fff;
                margin: 0;

                @include mobile-large {
                    line-height: rem(40);
                    text-align: center;
                    padding: 0 rem(12);
                }
            }

            >p {
                @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                font-weight: $FRegular;
                line-height: rem(22);
                color: #fff;
                width: 100%;
                max-width: rem(800);
            }
        }
    }

    .section-03 {
        display: flex;
        flex-direction: column;
        padding-bottom: rem(17);
        align-items: center;
        background-color: #fff;
        width: 100%;
        position: relative;
        background: url(../../src/images/interview/int17.webp);
        background-size: cover;

        .container {
            margin: 0;
        }

        .section__name {
            @include fluid-type(rem(620), rem(1400), rem(40), rem(64));
            font-weight: $FSemibold;
            font-family: $Oswald;
            line-height: rem(102);
            color: $black-theme;

            @include mobile-large {
                line-height: rem(64);
            }
        }

        .section__sub {
            @include fluid-type(rem(620), rem(1400), rem(14), rem(16));
            font-weight: $FBold;
            line-height: rem(25);
            color: $black2;
            margin-bottom: rem(40);

            @include mobile-large {
                margin-bottom: rem(16);
                line-height: rem(22);
            }
        }

        .other {
            display: flex;
            gap: rem(32);
            margin-bottom: rem(96);
            padding: 0 rem(16);

            @include mobile-large {
                flex-direction: column;
                gap: rem(16);
                margin-bottom: rem(64);

            }

            &__item {
                padding: rem(48) rem(64);
                display: grid;
                grid-template-columns: 1fr 1fr;
                border-radius: rem(20);
                border-top-right-radius: 0;
                border-bottom-left-radius: 0;
                position: relative;
                gap: rem(32);
                box-shadow: 0 rem(3) rem(6) rgba(0, 0, 0, 0.2);
                align-items: center;
                width: 100%;
                cursor: pointer;
                background-color: #fff;
                text-decoration: none;

                @include tablet-small {
                    padding: rem(32) rem(32);
                }

                @include mobile-large {
                    padding: rem(24);
                }

                >img {
                    width: 100%;
                    max-width: rem(160);
                }

                .detail {
                    display: flex;
                    flex-direction: column;
                    gap: rem(16);

                    >img {
                        width: 100%;
                        max-width: rem(87);
                    }

                    .name {
                        @include fluid-type(rem(620), rem(1400), rem(20), rem(20));
                        font-weight: $FBold;
                        line-height: rem(32);
                        color: $black-theme;
                    }

                    .text {
                        display: flex;
                        flex-direction: column;
                        border-left: rem(2) solid $black-theme;
                        padding: 0 rem(8);

                        >p {
                            @include fluid-type(rem(620), rem(1400), rem(13), rem(13));
                            font-weight: $FRegular;
                            line-height: rem(20);
                            color: $black2;
                        }
                    }
                }

                .circle {
                    background: $black-theme;
                    width: rem(10);
                    height: rem(10);
                    border-radius: 100%;
                    z-index: 1;
                    position: absolute;
                    top: 47%;
                    right: 11%;
                    transition: all 0.5s ease;
                }

                &:hover {
                    .circle {
                        background-color: $black-theme;
                        transform: scale(1.5);
                        transition: all 0.5s ease;
                    }
                }
            }
        }


    }



    .bg1 {
        position: absolute;
        top: rem(-51);
        right: -10%;
        width: 100%;
        max-width: rem(874);
        border-radius: rem(20);
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        z-index: -1;

        @include tablet {
            max-width: rem(634);
        }


        @include mobile-large {
            display: none;
        }
    }

    .bg2 {
        position: absolute;
        top: rem(-25);
        left: -12%;
        width: 100%;
        max-width: rem(241);
        z-index: -1;

        @include tablet {
            left: -5%;
        }

        @include mobile-large {
            max-width: rem(126);
            left: inherit;
            right: rem(15);
            top: rem(-30);
        }
    }

    .bg3 {
        width: 100%;
        margin-top: rem(20);
    }

    .bg4 {
        width: 100%;
    }


}