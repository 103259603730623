section.page-internship {
    background-color: $white2;

    .section-01 {
        padding: rem(48);
        margin-bottom: rem(96);
        gap: rem(27);
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;

        @include mobile-large {
            padding: rem(36) rem(16) rem(48) rem(16);
            gap: rem(40);
            margin-bottom: rem(64);

        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .row {
                padding: rem(20) 0;
                display: flex;
                border-bottom: rem(1) solid $gray3;
                width: 100%;

                @include mobile {
                    flex-direction: column;
                    gap: rem(8);
                    padding: rem(16) 0;

                }

                &:first-child {
                    border-top: rem(1) solid $gray3;
                }

                &__item {
                    @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                    font-weight: $FBold;
                    color: $black2;
                    line-height: rem(22);
                    width: 20%;
                    padding: 0;

                    @include mobile {
                        font-size: rem(16);
                        width: 100%;
                    }
                }

                &__description {
                    @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                    font-weight: $FRegular;
                    color: $black2;
                    line-height: rem(22);
                    width: 80%;
                    padding: 0;


                    @include mobile {
                        width: 100%;
                    }
                }
            }
        }

        .button {
            width: rem(280);
            height: rem(72);
            font-weight: $FBold;

            @include mobile {
                width: rem(228);
                height: rem(64);

            }

            &:hover {

                &::after {
                    background-color: $black-theme;
                }

                &::before {
                    background-color: #fff;
                }

                .btn-text {
                    &:first-child {
                        transform: translateY(0);
                    }

                    &:last-child {
                        transform: translateY(rem(-24));
                    }
                }

                .circle {
                    background-color: #fff;
                    transform: scale(1.5);
                    transition: all 0.5s ease;
                }

            }

            .btn-text {
                &:first-child {
                    color: #fff;
                    transform: translateY(rem(24));
                }

                &:last-child {
                    color: #fff;
                    transform: translateY(0);
                }

            }
        }
    }
}