@use 'sass:math';

//fluid typography
@function strip-unit($value) {
    @return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 ==$u2 and $u1 ==$u3 and $u1 ==$u4 {
        & {
            font-size: $min-font-size;

            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }

            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

@mixin absolute-position($top: auto, $right: auto, $bottom: auto, $left: auto) {
    bottom: $bottom;
    left: $left;
    position: absolute;
    right: $right;
    top: $top;
}

// media-queries
@mixin mobile-small {
    @media screen and (max-width: 375px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 620px) {
        @content;
    }
}

@mixin mobile-large {
    @media screen and (max-width: 800px) {
        @content;
    }
}

@mixin tablet-small {
    @media screen and (max-width: 1000px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin pc {
    @media screen and (max-width: 1440px) {
        @content;
    }
}

@mixin pc-large {
    @media screen and (max-width: 1920px) {
        @content;
    }
}