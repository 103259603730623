section.page-faq {
    background-color: $white2;

    .accordion {
        margin-bottom: rem(64);

        @include mobile-large {
            margin-bottom: rem(48);
        }
    }

    .breadscrumbs {
        margin-top: rem(32);
    }
}