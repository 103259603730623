section.page-entry {

    .container {
        display: flex;
        flex-direction: column;

        .subtitle {
            margin-bottom: rem(48);

            @include mobile-large {
                margin-bottom: rem(64);
            }
        }
    }

    .form-steps {
        align-self: center;
        position: relative;
        margin-bottom: rem(65);

        .step_desc {
            position: absolute;
            bottom: rem(-65);
            display: flex;
            flex-direction: column;
            gap: rem(4);
            align-items: center;

            .text1 {
                @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                font-weight: $FMedium;
                font-family: $Oswald;
                color: $black-theme;
                line-height: rem(22);
                align-items: baseline;

                >span {
                    @include fluid-type(rem(620), rem(1400), rem(25), rem(25));
                    font-weight: $FSemibold;
                    font-family: $Oswald;
                    color: $black-theme;
                    line-height: rem(40);
                }
            }

            .text2 {
                @include fluid-type(rem(620), rem(1400), rem(16), rem(16));
                font-weight: $FBold;
                color: $black-theme;
                line-height: rem(25);
            }
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        gap: rem(16);
        margin: rem(40) 0 rem(64) 0;
        align-self: center;

        @include mobile-large {
            margin-bottom: rem(48);
        }

        >p {
            @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
            font-weight: $FRegular;
            color: $black2;
            line-height: rem(22);
            text-align: center;

            >span {
                @include fluid-type(rem(620), rem(1400), rem(11), rem(11));
                font-weight: $FRegular;
                color: #fff;
                line-height: rem(17);
                border-radius: rem(8);
                padding: 0 rem(8);
                background-color: $red;
                margin-right: rem(4);
            }
        }
    }

    .descriptionError {
        display: none;

        >p {
            color: $red;
        }
    }

    .descriptionConfirm {
        display: none;
    }

    .form-content {
        margin-bottom: rem(96);

        @include mobile-large {
            margin-bottom: rem(64);
        }
    }

    .tabs-content {
        max-width: rem(800);
        width: 100%;

        .tab-form__content {
            display: flex;
            flex-direction: column;
            gap: rem(65);

            @include mobile-large {
                gap: rem(41);
            }
        }
    }

    .form-confirm {
        padding: rem(64) rem(16) rem(80) rem(16);
        background-color: $white2;
        margin-top: rem(8);
        display: flex;
        flex-direction: column;
        margin-bottom: rem(96);
        align-items: center;

        @include mobile-large {
            padding: rem(40) rem(16) rem(48) rem(16);
            margin-bottom: rem(64);

        }

        .content {
            width: 100%;
            max-width: rem(800);

            .formdata {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: rem(59);
                align-items: center;

                @include mobile-large {
                    margin-bottom: rem(40);
                }

                .row {
                    padding: rem(40) 0;
                    display: flex;
                    border-bottom: rem(1) solid $gray3;
                    width: 100%;
                    position: relative;

                    @include mobile-large {
                        flex-direction: column;
                        padding: rem(20) 0;

                    }

                    &:first-child {
                        border-top: rem(1) solid $gray3;
                    }

                    &__item {
                        @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                        font-weight: $FBold;
                        color: $black2;
                        line-height: rem(22);
                        width: 30%;
                        padding: 0;

                        @include mobile-large {
                            width: 100%;
                        }

                    }

                    .row__description {
                        @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                        font-weight: $FRegular;
                        color: $black2;
                        line-height: rem(22);
                        width: 70%;
                        padding: 0;
                        word-break: break-all;

                        @include mobile-large {
                            width: 100%;
                        }

                    }

                    .files {
                        display: flex;
                        width: 70%;
                        flex-direction: column;
                        gap: rem(16);

                        @include mobile-large {
                            width: 100%;
                            margin-top: rem(34);
                        }

                        .row__description {
                            border-bottom: rem(1) solid $black2;
                            position: relative;
                            padding-left: rem(28);
                            width: fit-content;


                            &::before {
                                content: "";
                                background-image: url(../../src/images/file_icon.webp);
                                background-size: cover;
                                width: rem(12);
                                height: rem(15);
                                left: 0;
                                margin: auto 0;
                                position: absolute;
                            }
                        }
                    }

                    .obs {
                        position: absolute;
                        top: rem(70);
                        left: rem(-12);

                        @include mobile-large {
                            top: rem(45);
                        }
                    }
                }
            }

            .buttons {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: rem(16);


            }
        }
    }

    .complete {
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: rem(40) 0 rem(96) 0;

        @include mobile-large {
            margin: rem(40) 0 rem(48) 0;
        }

        >h4 {
            @include fluid-type(rem(620), rem(1400), rem(20), rem(25));
            font-weight: $FBold;
            color: $black-theme;
            line-height: rem(40);
            margin-bottom: rem(25);

            @include mobile-large {
                line-height: rem(32);
            }
        }

        >p {
            @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
            font-weight: $FRegular;
            color: $black2;
            line-height: rem(20);
            margin-bottom: rem(80);
            text-align: center;

            @include mobile-large {
                margin-bottom: rem(48);
            }
        }


    }

    .file-block {
        @include mobile-large {
            gap: rem(42);
        }

        .content {

            @include mobile-large {
                gap: rem(16);
            }

            .obs {
                @include mobile-large {
                    margin: 0;
                }
            }
        }
    }
}