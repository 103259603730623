section.page-benefit {

    .cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: rem(33);
        margin-bottom: rem(80);

        @include mobile-large {
            grid-template-columns: 1fr 1fr;
            gap: rem(16);
            margin-bottom: rem(64);

        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: rem(338);
            height: rem(125);
            background-color: $white2;
            gap: rem(16);
            flex-direction: column;
            padding: rem(16);

            @include mobile-large {
                height: rem(98);
                gap: rem(8);
            }


            .text1 {
                @include fluid-type(rem(620), rem(1400), rem(14), rem(20));
                font-weight: $FBold;
                color: $green-theme;
                line-height: rem(32);
                text-align: center;
                width: 100%;
                max-width: rem(290);

                @include mobile-large {
                    line-height: rem(25);
                }
            }

            .text2 {
                @include fluid-type(rem(620), rem(1400), rem(13), rem(14));
                font-weight: $FRegular;
                color: $black-theme;
                line-height: rem(22);
                text-align: center;
                width: 100%;
                max-width: rem(290);

                @include mobile-large {
                    line-height: rem(20);
                }
            }

            .img {
                width: 100%;
                max-width: rem(80);

                @include mobile-large {
                    max-width: rem(57);
                }
            }

            &.item2 {
                height: rem(246);
                justify-content: baseline;

                @include mobile-large {
                    height: rem(258);
                }
            }

        }
    }

    .goals {
        padding: rem(40) rem(105);
        width: 100%;
        background-color: #fff;
        border-radius: rem(20);
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(16);
        margin-bottom: rem(96);

        @include mobile-large {
            padding: rem(40) rem(40);
            margin-bottom: rem(64);

        }

        @include mobile {
            padding: rem(40) rem(16);
        }

        .images {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            gap: rem(40);

            @include mobile {
                grid-template-columns: 1fr;
                gap: rem(24);
                justify-content: center;

            }

            >img {
                width: 100%;
            }
        }

        >p {
            @include fluid-type(rem(620), rem(1400), rem(13), rem(14));
            font-weight: $FRegular;
            color: $black-theme;
            line-height: rem(22);
            align-self: flex-end;

            @include mobile-large {
                line-height: rem(20);
            }
        }
    }
}