section.page-special {
    position: relative;
    // overflow: hidden;
    overflow: clip;
    padding-bottom: rem(17);

    .section-top {
        background-color: $green-theme;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-bottom: rem(-60);

        .subtitle {
            margin-bottom: 0;
        }

        .img1 {
            width: 100%;
            z-index: 1;
            height: rem(535);
            object-fit: cover;
            margin-top: rem(-40);

            @include mobile-large {
                display: none;
            }

            &.img1_mobile {
                height: auto;
                display: none;
                margin-top: 0;

                @include mobile-large {
                    display: block;
                }
            }
        }



        .bg1 {
            position: absolute;
            top: rem(50);
            right: 0;
            z-index: 0;
            width: 100%;
            max-width: rem(958);

            @include tablet-small {
                top: rem(98);
            }

            @include mobile-large {
                display: none;
            }

            &.bg1_mobile {
                display: none;
                top: inherit;
                bottom: rem(163);

                @include mobile-large {
                    display: block;
                }
            }
        }

        .bg2 {
            position: absolute;
            bottom: rem(-283);
            right: 0;
            z-index: 0;
            width: 100%;
        }
    }

    .section-left {
        display: flex;
        justify-content: flex-end;
        gap: rem(80);
        margin-bottom: rem(80);
        z-index: 1;
        position: relative;

        @include mobile-large {
            flex-direction: column;
            align-items: center;
            margin-top: rem(55);
            gap: rem(16);
            margin-bottom: 0;
        }

        .question {
            border-bottom-right-radius: 0;

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: rem(24) solid transparent;
                border-right: rem(24) solid transparent;
                border-bottom: rem(24) solid $green-theme;
                position: absolute;
                bottom: 0;
                right: rem(-24);

                @include mobile-large {
                    border-left: rem(16) solid transparent;
                    border-right: rem(16) solid transparent;
                    border-bottom: rem(16) solid $green-theme;
                    right: rem(-16);
                }
            }
        }

        .sub-question {
            border-bottom-right-radius: 0;

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: rem(24) solid transparent;
                border-right: rem(24) solid transparent;
                border-bottom: rem(24) solid $white2;
                position: absolute;
                bottom: 0;
                right: rem(-24);

                @include mobile-large {
                    border-left: rem(16) solid transparent;
                    border-right: rem(16) solid transparent;
                    border-bottom: rem(16) solid $white2;
                    right: rem(-16);
                }
            }
        }

        .person-img {
            margin-left: rem(-100);

            position: sticky;
            top: rem(120);

            @include tablet {
                margin-left: 0;
            }

            @include mobile-large {
                position: initial;
            }
        }
    }

    .section-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: rem(80);
        // margin-bottom: rem(-144);
        z-index: 1;
        position: relative;
        // overflow: hidden;
        overflow: clip;

        .question {
            border-bottom-left-radius: 0;

            @include mobile-large {
                align-self: center;
            }

            &::before {
                content: "";
                width: 0;
                height: 0;
                border-left: rem(24) solid transparent;
                border-right: rem(24) solid transparent;
                border-bottom: rem(24) solid $green-theme;
                position: absolute;
                bottom: 0;
                left: rem(-24);

                @include mobile-large {
                    border-left: rem(16) solid transparent;
                    border-right: rem(16) solid transparent;
                    border-bottom: rem(16) solid $green-theme;
                    left: rem(-16);
                }
            }
        }

        .sub-question {
            border-bottom-left-radius: 0;

            &::after {
                content: "";
                width: 0;
                height: 0;
                border-left: rem(24) solid transparent;
                border-right: rem(24) solid transparent;
                border-bottom: rem(24) solid $white2;
                position: absolute;
                bottom: 0;
                left: rem(-24);

                @include mobile-large {
                    border-left: rem(16) solid transparent;
                    border-right: rem(16) solid transparent;
                    border-bottom: rem(16) solid $white2;
                    left: rem(-16);
                }
            }
        }

        .person-img {
            margin-right: rem(-100);

            position: sticky;
            top: rem(120);

            @include tablet {
                margin-right: 0;
            }

            @include mobile-large {
                position: initial;
            }
        }

        .bg {
            position: absolute;
            // top: rem(-80);
            left: 0;
            width: 100%;
            z-index: -1;
            height: 100%;
        }

        .bg1 {
            width: 100%;
        }

        .bg2 {
            width: 100%;
            margin-top: rem(-55);
        }

        .inner {
            display: flex;
            justify-content: flex-start;
            gap: rem(80);
            margin-top: rem(-220);

            @include mobile-large {
                flex-direction: column-reverse;
                align-items: center;
                margin-top: rem(-64);
                gap: rem(16);
            }
        }
    }

    .special-slider {
        width: 100%;
        display: flex;
        margin-bottom: rem(32);
        z-index: 1;

        img {
            width: 100%;
            max-width: rem(485);
            height: rem(280);
            object-fit: cover;
            margin-left: rem(32);

            @include mobile-large {
                max-width: rem(277);
                height: rem(160);
            }
        }
    }

    .special-slider-right {
        margin-bottom: rem(96);

        @include mobile-large {
            margin-bottom: rem(64);
        }
    }

    .person-img {
        width: 100%;
        max-width: rem(434);
        object-fit: contain;
        align-self: baseline;

        @include tablet {
            max-width: rem(320);
        }

        @include tablet-small {
            max-width: rem(270);
        }

        @include mobile-large {
            display: none;
        }

        &.person-img_mobile {
            display: none;
            margin: 0 auto;

            @include mobile-large {
                display: block;
            }

            @include mobile-small {
                max-width: rem(345);
            }
        }
    }

    .themes {
        display: flex;
        flex-direction: column;
        gap: rem(64);
        width: 100%;

        @include mobile-large {
            gap: rem(48);
        }

        .theme-block {
            display: flex;
            flex-direction: column;

            .title {
                display: flex;
                align-items: baseline;
                gap: rem(4);
                @include fluid-type(rem(620), rem(1400), rem(22), rem(22));
                font-weight: $FSemibold;
                line-height: rem(22);
                font-family: $Oswald;
                color: $green-theme;
                margin-bottom: rem(8);

                >span {
                    @include fluid-type(rem(620), rem(1400), rem(40), rem(40));
                    line-height: rem(64);
                }
            }

            .question {
                padding: rem(24) rem(32);
                display: flex;
                align-items: center;
                background-color: $green-theme;
                border-radius: rem(20);
                position: relative;
                @include fluid-type(rem(620), rem(1400), rem(20), rem(25));
                font-weight: $FBold;
                line-height: rem(40);
                color: #fff;
                margin-bottom: rem(40);
                width: 95%;

                @include mobile-large {
                    line-height: rem(32);
                    padding: rem(16) rem(24);
                    margin-bottom: rem(32);

                }
            }

            .answer {
                display: flex;
                flex-direction: column;

                &-item {
                    display: flex;
                    gap: rem(32);
                    padding: rem(16) 0;
                    border-bottom: rem(1) solid $gray3;

                    @include mobile-large {
                        gap: rem(24);
                    }

                    .photo {
                        display: flex;
                        flex-direction: column;
                        gap: rem(8);
                        align-items: center;

                        >img {
                            width: 100%;
                            max-width: rem(64);

                            @include mobile-large {
                                max-width: rem(48);
                            }
                        }

                        >p {
                            @include fluid-type(rem(620), rem(1400), rem(13), rem(14));
                            font-weight: $FBold;
                            line-height: rem(22);
                            color: $black2;

                            @include mobile-large {
                                line-height: rem(20);
                            }
                        }
                    }

                    .text {
                        width: 80%;
                        @include fluid-type(rem(620), rem(1400), rem(14), rem(14));
                        font-weight: $FRegular;
                        line-height: rem(22);
                        color: $black-theme;

                        height: fit-content;
                        min-height: rem(64);
                        display: flex;
                        align-items: center;

                    }
                }
            }

            .sub-question {
                padding: rem(24) rem(32);
                display: flex;
                align-items: center;
                background-color: $white2;
                border-radius: rem(20);
                position: relative;
                @include fluid-type(rem(620), rem(1400), rem(14), rem(16));
                font-weight: $FBold;
                line-height: rem(25);
                color: $black2;
                margin: rem(32) 0;
                width: 95%;

                @include mobile-large {
                    line-height: rem(22);
                    padding: rem(16) rem(24);
                    margin: rem(24) 0;
                    margin-bottom: rem(8);
                }
            }

        }
    }

    .section-04 {
        @include mobile-large {
            margin-top: rem(10);
            margin-bottom: rem(48);
        }
    }
}