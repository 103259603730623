header {
    width: 100%;
    box-sizing: border-box;

    .container {
        width: 100%;
        padding: rem(14) rem(40);
        display: flex;
        justify-content: space-between;
        position: fixed;
        top: 0;
        z-index: 10;

        @include mobile {
            padding: rem(15);
        }

        .right {
            display: flex;
            gap: rem(40);
            align-items: center;

            .button {
                width: rem(101);
                height: rem(63);
                font-weight: $FSemibold;
                font-family: $Oswald;


            }

            >.button {
                @include mobile {
                    display: none;
                }
            }


        }


    }

    .left {
        display: flex;
        align-items: center;
        gap: rem(15);
        text-decoration: none;
        width: max-content;

        .logo {
            width: 100%;
            max-width: rem(102);

            @include mobile-large {
                max-width: rem(76);
            }
        }

        >p {
            @include fluid-type(rem(620), rem(1400), rem(14), rem(25));
            font-weight: $FBold;
            color: $white;

            @include mobile {
                font-family: $Oswald;
                font-weight: $FMedium;
            }
        }

        .dark-theme {
            color: $white;
        }

        .light-theme {
            color: $black-theme;
        }
    }

    .overlay-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .menu_container {
            display: flex;
            justify-content: space-between;
            align-self: center;
            height: 100%;

            @include mobile-large {
                flex-direction: column;
                height: auto;
                padding: 0;
            }

            .menuleft {
                width: 40%;
                min-width: rem(330);
                display: flex;
                justify-content: space-between;
                margin-top: rem(100);

                @include mobile-large {
                    display: none;
                }

                .column {
                    display: flex;
                    flex-direction: column;
                    gap: rem(24);

                    .item {
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;

                        &_name {
                            @include fluid-type(rem(620), rem(1400), rem(25), rem(25));
                            font-family: $Oswald;
                            font-weight: $FSemibold;
                            color: $green-theme;
                            line-height: rem(40);

                            &.subitem {
                                color: $gray2
                            }
                        }

                        &_subname {
                            @include fluid-type(rem(620), rem(1400), rem(13), rem(13));
                            font-weight: $FRegular;
                            color: #fff;
                            line-height: rem(20);

                        }

                    }
                }
            }

            .menuright {
                width: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: rem(24);
                justify-content: center;
                margin-top: rem(-80);

                @include mobile-large {
                    margin-top: 54px;
                    width: 100%;
                }

                >h2 {
                    @include fluid-type(rem(620), rem(1400), rem(100), rem(100));
                    font-family: $Oswald;
                    font-weight: $FSemibold;
                    color: #fff;
                    line-height: rem(100);

                    @include mobile-large {
                        display: none;
                    }
                }

                .button {
                    width: rem(280);
                    height: rem(72);
                    font-weight: $FBold;

                    @include mobile-large {
                        width: rem(300);
                    }
                }

            }

            .menu-mobile {
                display: none;
                flex-direction: column;
                border-top: rem(1) solid $gray4;
                margin-top: rem(40);

                @include mobile-large {
                    display: flex;
                }

                .menu-item {
                    position: relative;
                    display: none;
                    align-items: center;
                    cursor: pointer;
                    border-bottom: rem(1) solid $gray4;

                    @include mobile-large {
                        display: flex
                    }

                    >a {
                        @include fluid-type(rem(620), rem(1400), rem(32), rem(32));
                        font-weight: $FSemibold;
                        color: $green-theme;
                        font-family: $Oswald;
                        line-height: rem(51);
                        text-decoration: none;
                        width: 100%;
                        padding: rem(8) 0;
                        text-align: left;
                    }

                    &::after {
                        content: "";
                        width: rem(8);
                        height: rem(8);
                        border-radius: 50%;
                        background-color: #fff;
                        position: absolute;
                        margin: auto 0;
                        right: 5%;
                    }

                    &.accordion-button-menu {
                        background-color: transparent;
                        box-shadow: none;
                        padding: 0;
                        align-items: center;
                        width: 100%;
                        border: none;

                        >a {
                            width: fit-content;
                        }

                        &::after {
                            opacity: 0.2;
                        }

                        &:not(.collapsed) {
                            &::after {
                                opacity: 1;
                            }
                        }

                    }

                }

            }
        }

        .menu_footer {
            display: flex;
            gap: rem(40);
            align-self: center;

            @include mobile-large {
                display: none;
            }

            > {

                a,
                p {
                    @include fluid-type(rem(620), rem(1400), rem(13), rem(13));
                    font-weight: $FMedium;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}

.accordion-item-menu {
    margin-bottom: 0;
    background-color: transparent;

    .accordion-collapse {
        padding-bottom: rem(10);
    }

    .accordion-body-menu {
        margin-top: rem(-10);

        .submenu-item {
            border: none;
            display: flex;
            align-items: center;
            position: relative;

            >a {
                @include fluid-type(rem(620), rem(1400), rem(32), rem(32));
                font-weight: $FSemibold;
                color: $gray2;
                font-family: $Oswald;
                line-height: rem(51);
                text-decoration: none;
                width: 100%;
                text-align: left;
                margin-left: rem(16);
            }

            &::after {
                content: "";
                width: rem(8);
                height: rem(8);
                border-radius: 50%;
                background-color: #fff;
                position: absolute;
                margin: auto 0;
                right: 5%;
            }
        }
    }

}

#accordionMenu {
    border-bottom: rem(1) solid $gray4;
}